import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import Spinner from '@/components/common/Spinner';
import { Mousewheel, Pagination } from "swiper";
import { validateEmail } from '@/helper/common';
import { isSubscribed, subscribe } from '@/services/firebase/fireStore';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import {
  mute, unmute
} from "@/config/svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";





const Home2: React.FC = () => {

  const { translated,ClaireLoader, authUser, videoUrl, videoUrlMobile } = useContext(AppContext);
  const fetchCalled = useRef(false);


  const [isMiniLoader, setIsMiniLoader] = useState(false);
  const scollToRef = useRef();

  useEffect(() => {
    const video = document.getElementById('video-js') as HTMLVideoElement;
    const videoM = document.getElementById('video-js-m') as HTMLVideoElement;

    //console.log(video);
    if (fetchCalled.current === false && videoUrlMobile && videoUrl && video) {
      fetchCalled.current = true;
      const source = document.createElement('source');
      const sourceM = document.createElement('source');

      if (window.innerWidth < 900) {
        sourceM.setAttribute('src', videoUrlMobile);
        sourceM.setAttribute('type', 'video/mp4');
        videoM.appendChild(sourceM);
        videoM.play();
      } else {
        source.setAttribute('src', videoUrl);
        source.setAttribute('type', 'video/mp4');
        video.appendChild(source);
        video.play();
      }

     






      function makeNewPosition() {
        var h, w = 0;

        const wh = $(window).height();
        const ww = $(window).width();

        if (typeof wh !== "undefined" && typeof ww !== "undefined") {
          h = wh - 50;
          w = ww - 50;
        }

        var nh, nw = 0;
        if (typeof h !== "undefined" && typeof w !== "undefined") {
          nh = Math.floor(Math.random() * h);
          nw = Math.floor(Math.random() * w);
        }
        return [nh, nw];
      }

      function animateDiv(myclass: string) {
        var newq = makeNewPosition();
        $(myclass).animate({ top: newq[0], left: newq[1] }, 5000, function () {
          animateDiv(myclass);
        });
      };
    }
  });

  const [email, setEmail] = useState("");
  const [consent, setConsent] = useState(false);
  const [disable, setDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [resultMsg, setResultMsg] = useState('');

  const updateEmail = (inputValue: string) => {


    setEmail(inputValue);

  };

  const updateConsent = (inputValue: any) => {
    setConsent(inputValue);
  }

  const handleSubmitData = async () => {
    if (!email || !validateEmail(email)) {
      setErrorMsg(translated('error.insert_a_valid_email'));
    }
    else if (!consent) {
      setErrorMsg(translated('error.the_conset_is_mandatory'));
    }
    else {
      setIsMiniLoader(true);

      var user_id = authUser?.authUId;


      isSubscribed(email).then((data: false | QuerySnapshot<DocumentData>) => {
        if (data) {
          if (!data.empty) {
            setErrorMsg(translated('error.mail_already_used'));
            $('.result_msg').fadeOut();
            setIsMiniLoader(false);

            setTimeout(function () {
              $('.result_msg').fadeOut();
            }, 5000);
          }
          else {
            let dt = new Date();
            let isodt = toIsoString(dt);

            const newUser = {
              date_time: isodt,
              email: email,
              uid: user_id
            }
            subscribe(email, newUser)
              .then(() => {
                setConsent(false);
                setEmail('');
                setErrorMsg('');
                setResultMsg(translated('success.thank_you_signup'));
                $('.result_msg').fadeIn();
                $('.email-subscribe-input, .consent-checkbox, .medium-title').hide();
                setIsMiniLoader(false);

                setTimeout(function () {
                  $('.result_msg').fadeOut();
                  $('.email-subscribe-input, .consent-checkbox, .medium-title').show();
                  //$("#std-pre-launch").show();
                }, 5000);
              })
              .catch(() => {
                setConsent(false);
                setEmail('');
                setErrorMsg(translated('error.something_wrong'));
                $('.result_msg').fadeIn();
                setIsMiniLoader(false);

                setTimeout(function () {
                  $('.result_msg').fadeOut();
                }, 5000);
              });

          }
        }
      });
    }
  }

  function toIsoString(date: any) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  const [mutedBtnText, setMutedBtnText] = useState("Unmute");

  const unmuteVideo = () => {
    const video = document.getElementById('video-js') as HTMLVideoElement;
    const videoM = document.getElementById('video-js-m') as HTMLVideoElement;
    video.muted = !video.muted;
    videoM.muted = !videoM.muted;
    if (video.muted) {
      setMutedBtnText("Unmute");
    }
    else {
      setMutedBtnText("Mute")
    }
    if (videoM.muted) {
      setMutedBtnText("Unmute");
    }
    else {
      setMutedBtnText("Mute")
    }


  }

  useEffect(() => {
    document.body.className = "hp2-pg";
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <>
      <Pages>
        <Helmet>
          <title>Claire, Terms Of Use.</title>
          <meta name="description" content="Claire, Terms Of Use." />
        </Helmet>
        <div className="shadow-fixed"></div>
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          speed={2500}
          modules={[Mousewheel, Pagination]}
          className="mySwiper"

          onSlideChangeTransitionStart={(swiper) => {

            const video = document.getElementById('video-js') as HTMLVideoElement;
            if (swiper.realIndex == 0) {
              $('.footer-wrapper').addClass('hide-footer');
              // video.play();

            } else {
              $('.footer-wrapper').removeClass('hide-footer');


              video.pause();
            }

          }}
          onSlidePrevTransitionEnd={(swiper) => {
            const video = document.getElementById('video-js') as HTMLVideoElement;
            if (swiper.realIndex == 0) {
              $('.footer-wrapper').addClass('hide-footer');
              video.play();
            } else {
              $('.footer-wrapper').removeClass('hide-footer');
              video.pause();
            }
          }}
          onBeforeInit={
            (swiper) => {
              if (window.innerWidth < 900) {
                console.log('im mobile');
              } else {
              
                  if (swiper.realIndex == 0) {
                    $('.footer-wrapper').addClass('hide-footer');
                  } else {
                    $('.footer-wrapper').removeClass('hide-footer');
                  }
                

              }



            }
          }
          onSwiper={(swiper) => {
            if (window.location.href.indexOf('?hp') > -1) {
              swiper.slideTo(1);
            }
           
          }}

        >
          <SwiperSlide> <div className="page-section section-1">
            <button className="unmute-video" onClick={(e) => unmuteVideo()}>

              <img src={mutedBtnText == 'Mute' ? unmute : mute}></img>
            </button>
            <div className="video-background-wrapper">
              <video id="video-js" playsInline autoPlay muted loop>
              </video>
            </div>
            <div className="c-scrolldown">

              <span className="c-text color-blue">What we're about</span>
              <div className="c-line"></div>
            </div>
          </div></SwiperSlide>
          <SwiperSlide>
            <div >


              <div className="sub-section2"  >
                <div className="d-flex-center h100 hp-text">
                  <div className="d-flex-center infos">
                    <h3 className="medium-title coming-spring color-blue">
                      {translated('app_copy.i_want_to_know_more')}
                    </h3>
                    <div className="title">
                      {translated('app_copy.intro_title')}
                    </div>
                    <div className="text ">
                      {translated('app_copy.intro_text')}
                    </div>
                  </div>

                </div>
              </div>
              <div className="c-scrolldown">

                <span className="c-text color-blue">Keep in the loop</span>
                <div className="c-line"></div>
              </div>



            </div>

          </SwiperSlide>
          <SwiperSlide>
            <div  >


              <div className="sub-section2"  >
                <div className="d-flex-center h100 hp-text">
                  <div className="d-flex-center ">

                    <div className="title slide3">
                      Want to know when we launch?<br></br>
                      We can keep you in the loop.
                    </div>

                  </div>
                  <div className="d-flex-center  coming-soon-wrapper">

                    <div className="email-subscribe-input  mt-3">
                      <input type="email" name="email-subscribe" id="email-subscribe" placeholder={translated('app_copy.enter_your_email')} autoFocus={true} style={errorMsg ? { border: '1px solid red' } : {}}
                        onChange={(e) => updateEmail(e.target.value)} value={email} />
                      <button type="submit" className="submit color-white" disabled={!disable}
                        onClick={handleSubmitData} name="Submit">{translated('cta.submit')}</button>
                    </div>
                    {errorMsg &&
                      <p className="error_msg color-red ">{errorMsg}</p>
                    }
                    {
                      isMiniLoader ? (<ClaireLoader style={{height:'100%',width:'100px',margin:'auto'}} />) : <></>
                    }
                    <div className="consent-checkbox" >
                      <input type="checkbox" name="consent" id="consent-checkbox" checked={consent} onChange={e => updateConsent(e.target.checked)} />
                      <label htmlFor="consent-checkbox">
                        <span className="body-text-small color-gray">
                          {translated('app_copy.checkbox_consent')}
                        </span>
                      </label>
                    </div>
                    {resultMsg &&
                      <p className="result_msg color-blue m-1 bold">{resultMsg}</p>
                    }
                  </div>
                </div>
              </div>



            </div>

          </SwiperSlide>

        </Swiper>
        <div className="mobile-hp2">
          <div className="page-section section-1 pg-mb">
            <button className="unmute-video" onClick={(e) => unmuteVideo()}>

              <img src={mutedBtnText == 'Mute' ? unmute : mute}></img>
            </button>
            <div className="video-background-wrapper">
              <video id="video-js-m" playsInline autoPlay muted loop>
              </video>
            </div>
            <div className="c-scrolldown">

              <span className="c-text color-blue">What we're about</span>
              <div className="c-line"></div>
            </div>
          </div>
          <div className="pg-mb textual">


            <div className="sub-section2"  >
              <div className="d-flex-center h100 hp-text">
                <div className="d-flex-center infos">
                  <h3 className="medium-title coming-spring color-blue">
                    {translated('app_copy.i_want_to_know_more')}
                  </h3>
                  <div className="title">
                    {translated('app_copy.intro_title')}
                  </div>
                  <div className="text ">
                    {translated('app_copy.intro_text')}
                  </div>
                </div>

              </div>
            </div>
            <div className="c-scrolldown">

              <span className="c-text color-blue">Keep in the loop</span>
              <div className="c-line"></div>
            </div>



          </div>


          <div className="pg-mb textual">


            <div className="sub-section2"  >
              <div className="d-flex-center h100 hp-text">
                <div className="d-flex-center ">

                  <div className="title slide3">
                    Want to know when we launch?<br></br>
                    We can keep you in the loop.
                  </div>

                </div>
                <div className="d-flex-center  coming-soon-wrapper">

                  <div className="email-subscribe-input  mt-3">
                    <input type="email" name="email-subscribe" id="email-subscribe" placeholder={translated('app_copy.enter_your_email')} autoFocus={true} style={errorMsg ? { border: '1px solid red' } : {}}
                      onChange={(e) => updateEmail(e.target.value)} value={email} />
                    <button type="submit" className="submit color-white" disabled={!disable}
                      onClick={handleSubmitData} name="Submit">{translated('cta.submit')}</button>
                  </div>
                  {errorMsg &&
                    <p className="error_msg color-red ">{errorMsg}</p>
                  }
                  {
                    isMiniLoader ? (<ClaireLoader style={{height:'100%',width:'100px',margin:'auto'}} />) : <></>
                  }
                  <div className="consent-checkbox" >
                    <input type="checkbox" name="consent" id="consent-checkbox" checked={consent} onChange={e => updateConsent(e.target.checked)} />
                    <label htmlFor="consent-checkbox">
                      <span className="body-text-small color-gray">
                        {translated('app_copy.checkbox_consent')}
                      </span>
                    </label>
                  </div>
                  {resultMsg &&
                    <p className="result_msg color-blue m-1 bold">{resultMsg}</p>
                  }
                </div>
              </div>
            </div>



          </div>

        </div>





      </Pages>
    </>
  );
};



export default Home2;
