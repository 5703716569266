import { get } from 'firebase/database';
import { doc, setDoc, getDoc, collection, where, orderBy, limit } from '@firebase/firestore'
import { fireStore } from '@/services/firebase/index'
import { deleteDoc, getDocs, onSnapshot, query } from 'firebase/firestore';
export const getFireStoreDoc = async (collectionName: string) => {
  try {
    const q = doc(fireStore, collectionName);

    const querySnapshot = await getDoc(q);
    console.log("querySnapshot", querySnapshot.data());
    // querySnapshot.forEach((doc) => {
    //   // doc.data() is never undefined for query doc snapshots
    // });
    return querySnapshot.data();
  } catch (err) {
    console.log(err);
    return false
  }
}

export const getRTDBData = async (collectionName: string) => {
  try {
    return onSnapshot(doc(fireStore, collectionName), (doc: any) => {
      console.log(doc.data());
      return doc.data();
    });
  } catch (err) {
    console.log(err);
    return false
  }
}


export const isSubscribed = async (email: string) => {
  const users = collection(fireStore, 'pre_launch_email_list')
  try {
    return await getDocs(query(users, where('email', '==', email)));
  } catch (err) {
    console.log(err);
    return false
  }
}

export const subscribe = async (collectionName: string, data: any) => {
  try {
    return await setDoc(doc(fireStore, 'pre_launch_email_list', collectionName), { ...data });
  } catch (err) {
    console.log(err);
    return false
  }
}

export const unSubscribe = async (userCollection: string|any) => {
  try {
    return await deleteDoc(doc(fireStore, 'pre_launch_email_list', userCollection));
  } catch (err) {
    console.log(err);
    return false
  }
}

export const getJobs = async () => {
  const db = collection(fireStore, 'jobs');
  //clone data to a new collection
  //   const dt = await getDocs(query(db));
  //   dt.docs.forEach(async item => {
  //     //console.log('item.id:' +item.id + '-->'+item.data().job_id);
  //     await setDoc(doc(fireStore, 'jobs', item.data().job_id), item.data());
  //   });
  //var now = new Date(new Date().toUTCString());
  //console.log('now:', now);
  try {
    return await getDocs(query(db, where('status', '==', true), orderBy("title")));
  } catch (err) {
    console.log(err);
    return false
  }
}

export const getJobDetails = async (job_id: any) => {
  const docRef = doc(fireStore, 'jobs', job_id);
  //const db = collection(fireStore, 'jobs');
  //const q = query(db, job_id, limit(1));
  try {
    return await getDoc(docRef);
  } catch (err) {
    //console.log(err);
    return false
  }
}

export const submitApplication = async (title: any, data: any) => {
  const dbRef = doc(fireStore, 'job_application', 'applications', title, data.email);
  try {
    await setDoc(dbRef, {...data});
    return true;
  } catch (err) {
    //console.log(err);
    return false
  }
}

export const isApplied = async (collectionName: any, email: string) => {
  const docRef = doc(fireStore, 'job_application', 'applications', collectionName, email);
  try {
    return await getDoc(docRef);
  } catch (err) {
    console.log(err);
    return false
  }
}

export const allApplications = async () => {
  const db = collection(fireStore, 'job_application');
  try {
    return await getDocs(query(db));
  } catch (err) {
    console.log(err);
    return false
  }
}

export const applicationsByJob = async (title: any) => {
  const docRef = doc(fireStore, 'job_application', 'applications');
  const colRef = collection(docRef, title);
  try {
    return await getDocs(colRef);
  } catch (err) {
    console.log(err);
    return false
  }
}

export const settings = async () => {
  const docRef = doc(fireStore, 'project_configurations', 'settings');
  try {
    return await getDoc(docRef);
  } catch (err) {
    console.log(err);
    return false
  }
}

export const getLeadership = async () => {
  const docRef = doc(fireStore, 'leadership', 'profile');
  try {
    return await getDoc(docRef);
  } catch (err) {
    //console.log(err);
    return false
  }
}

export const getLeadershipDetails = async (profile_key:any) => {
  const docRef = doc(fireStore, 'leadership', 'profile');
  try {
    let profiles = await getDoc(docRef);
    if(profiles) {
        const leadershipData = profiles.data();
        if(leadershipData) {
          return leadershipData[profile_key];
        }
      }
  } catch (err) {
    //console.log(err);
    return false
  }
}

export const getConfigurableText = async (job_id: any) => {
  const docRef = doc(fireStore, 'configurable_text','configurable_text');
  try {
    let rs = await getDoc(docRef);
    if(rs) {
      //console.log(rs.data());
      return rs.data();
    }
  } catch (err) {
    console.log(err);
    return false
  }
}