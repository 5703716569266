import React, { useEffect, useState, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import {
  twoPhones,
  doctorPhone,
  justforyouPhone,
  femaleDoctor,
  back,
  food,
  graphic,
  
} from "@/config/svg";
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';


const HealthPlans: React.FC = () => {
  const { translated } = useContext(AppContext)
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    document.body.className = "who-pg ";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={!isLoader} minTime={4000} scale={0.25} className="normal-page-loader">
          <Helmet>
            <title>Claire, Health Plans</title>
            <meta name="description" content="Claire, Health Plans." />
          </Helmet>
          <div className="shadow-fixed"></div>
          <div className="back-description ">
            <Link to="/who-we-serve" state={{ prevPage: "health-plans" }} className="link-text color-black">
              <img src={back} alt="Back" className="mr-1" />
              <span className="body-text back-cta">{translated('cta.back')}</span>
            </Link>
            <div className="desc">
              <span></span><p>{translated('app_copy.health_plans_title')}</p>
            </div>
          </div>
          <div className=" block-1">
            <div className="left-txt">
              {translated('app_copy.health_plans_top_left_text')}
            </div>
            <div className="right-img"><img src={twoPhones} alt='phones'></img></div>
          </div>

          <div className="block-3 health">
            <div className="left-img">
              <img className="desk-only" src={justforyouPhone} alt='phone-screen'></img>
            </div>
            <div className="right-text">
              <div className="text-1">
                {translated('app_copy.health_plans_top_right_text')}
              </div>
              <div className="left-img">
                <img className="mob-only" src={justforyouPhone} alt='phone-screen'></img>
              </div>
              <div className="box">
                <div className="title">
                  {translated('app_copy.health_plans_what_makes_claire')}
                </div>
              </div>
            </div>

          </div>


          <div className="block-4 health position-relative">
            <div><img src={food} alt='doctor'></img></div>

            <div className="floating-box-right health">
                {translated('app_copy.health_plans_claire_offer')}
            </div>

          </div>
         {/* <div className="graphic">
            {translated('app_copy.health_plans_claire_experience')}
            <img className="desk-only" src={graphic} alt='graphic'></img>
            <img className="mob-only" src={graphicMobile} alt='graphic'></img>
  </div>*/}
         {/* <div className="block-4 position-relative switched">
            <div className="purple-box">
              <div className="floating-box-left">
                {translated('app_copy.health_plans_example_use_case')} 
              </div>
            </div>
            <div className="health-img">
              <img src={doctorPhone} alt='doctor'></img>
            </div>
          </div>*/}

          <div className="block-5 health">
            <div className="box">
                {translated('app_copy.health_plans_gaps_in_care')}
            </div>
            <div className="box">
                {translated('app_copy.health_plans_member_engagement')}
            </div>
            <div className="box">
                {translated('app_copy.health_plans_education_support')}
            </div>
          </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default HealthPlans;
