import routes from "./config/routes";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import MasterContent from "@/components/MasterContent";
import {
  Home,
  Response404,
  Unsubscribe,
  WorkWithUs,
  JobDetails,
  WhoWeServe,
  DigitalHealthProvider,
  ProviderAndClinic,
  HealthPlans,
  ContactUs,
} from "@/pages/index";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "./context/createContext";
import { isLogin } from "./helper/common";
import Backers from './pages/Backers';
import Leadership from './pages/Leadership';
import LeadershipDetails from './pages/LeadershipDetails';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home2 from './pages/Home2';


const AppRouter = () => (
  <Routes>
    <Route path="/" element={<MasterContent />}>
      {/** Protected Routes */}
      <Route path="/" element={<ProtectedArea />}>
        <Route path="/" element={<Home />} />
        {/* <Route path="/work-with-us" element={<WorkWithUs />} />
        <Route path="/job-detail" element={<JobDetail />} /> */}
        <Route path="/" element={<Authenticated />}>
        </Route>
      </Route>

      {/** Public Routes */}
      <Route path="/" element={<PublicArea />}>
        <Route path={routes.unsubscribe} element={<Unsubscribe />}></Route>
        <Route path={routes.workWithUs} element={<WorkWithUs />}></Route>
        <Route path={routes.jobDetails} element={<JobDetails />}></Route>
        <Route path={routes.backers} element={<Backers />}></Route>
        <Route path={routes.leadership} element={<Leadership />}></Route>
        <Route path={routes.leadershipDetails} element={<LeadershipDetails />}></Route>
        <Route path={routes.TermsOfUse} element={<TermsOfUse />}></Route>
        <Route path={routes.PrivacyPolicy} element={<PrivacyPolicy />}></Route>
        <Route path={routes.Home2} element={<Home2 />}></Route>
        <Route path={routes.WhoWeServe} element={<WhoWeServe /> }></Route>
        <Route path={routes.DigitalHealthProvider} element={<DigitalHealthProvider />}></Route>
        <Route path={routes.ProviderAndClinic} element={<ProviderAndClinic /> }></Route>
        <Route path={routes.HealthPlans} element={<HealthPlans /> }></Route>
        <Route path={routes.ContactUs} element={<ContactUs /> }></Route>
      </Route>

      {/** Without Permission route */}
      <Route path="/*" element={<Response404 />}></Route>
      
    </Route>
  </Routes>
);

function ProtectedArea() {
  // const { authUser } = useContext(AppContext);
  // const [isLoggedIn, setIsLogin] = useState(false);
  // // let location = useLocation();
  // useEffect(() => {
  //   if (authUser?.authUId) {
  //     setIsLogin(isLogin(authUser?.authUId));
  //   }
  // }, [authUser]);
  // if (!auth.authUId && !auth.authToken) {
  // if (
  //   isLoggedIn &&
  //   (authUser?.userInfo?.user_data?.consent === undefined ||
  //     !authUser?.userInfo?.user_data?.consent)
  // ) {
  //   // Redirect them to the /login page, but save the current location they were
  //   // trying to go to when they were redirected. This allows us to send them
  //   // along to that page after they login, which is a nicer user experience
  //   // than dropping them off on the home page.
  //   return <Navigate to="/consent" />;
  // } else {
  return <Outlet />;
  //}
}

const Authenticated = (props: any) => {
  let authToken = localStorage.getItem("authToken");
  let isOtpVerify = localStorage.getItem("isOtpVerify");
  let location = useLocation();

  // let location = useLocation();
  // const { authUser } = useContext(AppContext);
  // const [isLoggedIn, setIsLogin] = useState(true);
  // useEffect(() => {
  //   if (authUser?.authUId) {
  //     setIsLogin(isLogin(authUser?.authUId));
  //   }
  // }, [authUser]);
  // return isLoggedIn ? (
  return authToken && isOtpVerify === "true" ? (
    <Outlet />
  ) : (
    <Navigate to={routes.root} state={{ from: location }} replace />
  );
};

const PublicArea = (props: any) => {
  // let authToken = localStorage.getItem("authToken");
  // let isOtpVerify = localStorage.getItem("isOtpVerify");
  const { authUser } = useContext(AppContext);
  const [isLoggedIn, setIsLogin] = useState(false);
  // let location = useLocation();
  useEffect(() => {
    if (authUser?.authUId) {
      setIsLogin(isLogin(authUser?.authUId));
    }
  }, [authUser]);
  return isLoggedIn ? <Navigate to="/" /> : <Outlet />;
};

export default AppRouter;
