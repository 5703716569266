// eslint-disable-next-line import/no-anonymous-default-export
export default {
    root: '/',
    compoentscom: '/components',
    unsubscribe: '/unsubscribe',
    workWithUs: '/work-with-us',
    jobDetails: '/job/:job_id',
    backers: '/backers',
    TermsOfUse: '/terms-of-use',
    PrivacyPolicy: '/privacy-policy',
    leadership: '/leadership',
    leadershipDetails: '/leadership/:profile_key',
    Home2: '/home2',
    WhoWeServe: '/who-we-serve',
    HealthPlans: '/health-plans',
    DigitalHealthProvider: '/digital-health-provider',
    ProviderAndClinic: '/provider-and-clinic',
    ContactUs: '/contact-us',
}
