import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "@/context/createContext";

export default function Footer() {
  const { translated } = useContext(AppContext);

  return (
    <div className="footer-wrapper">
      <footer className="footer-main">
        <span className="body-text">{ translated('app_copy.footer_copyright_text') }</span>
        <div className="footer-links">
        <Link to="/contact-us" className="link-text t-contact">
            { translated('app_copy.footer_contact_us') }
          </Link>
          <Link to="/terms-of-use" className="link-text t-use">
            { translated('app_copy.menu_tos') }
          </Link>
          <Link to="/privacy-policy" className="link-text privacy-p">
            { translated('app_copy.menu_privacy_policy') }
          </Link>
        </div>
      </footer>
    </div>
  );
}
