import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "@/context/createContext";
import React, { useState } from "react";
import {
  ClaireLogoBlue,
  ClaireLogoWhite,
  menuIcon,
  closeIcon,
  menuIconBlue
} from "@/config/svg";

export default function Header(): JSX.Element {
  const [show, setShow] = useState(false);
  const { translated } = useContext(AppContext)
  const handleClick = (e: any) => {
    setShow(current => !current);
    document.body.style.overflowY = !show ? 'hidden' : 'unset';
  };
  return (
    <div className={ (show) ?  "header-cnt " : "header-normal"}>
      <div className={(show) ? "show-menu menu mobile " : "menu mobile hide"}>
        <img src={closeIcon} className='close-icon' onClick={handleClick}></img>
        <div className="menu-item">
          <Link to="/?hp" className="link-text color-white me-3" onClick={handleClick}>
            Home
          </Link>
          <Link to="/backers" className="link-text color-white me-3" onClick={handleClick}>
            {translated('app_copy.backers_title')}
          </Link>
          <Link to="/leadership" className="link-text color-white me-3" onClick={handleClick}>
            {translated('app_copy.leadership_title')}
          </Link>
          <Link to="/who-we-serve" className="link-text color-white me-3" onClick={handleClick}>
            {translated('app_copy.who_we_serve')}
          </Link>
          <Link to="/work-with-us" className="link-text color-white me-3" onClick={handleClick}>
            {translated('app_copy.work_with_us')}
          </Link>
        </div>
      </div>
    <div className="header-wrapper">
      <header className="header-main">
        <div className="claire-logo desk">
          <Link to="/">
            <img src={ClaireLogoBlue} alt="Claire" />
          </Link>
          </div>
          <div className="claire-logo-m mobile">
          <Link to="/">
            <img src={ClaireLogoBlue} alt="Claire" className="hp-logo"  />
            <img src={ClaireLogoBlue} alt="Claire" className="page-logo"  />
          </Link>
          <img src={menuIconBlue} className='menu-logo hp-logo' onClick={handleClick}></img>
          <img src={menuIconBlue} className='menu-logo page-logo' onClick={handleClick}></img>
        </div>
        <div className="menu desk">
          <div className="menu-item">
          <Link to="/?hp" className="link-text color-blue transition-to-white  hp-bold">
              Home
            </Link>
            <Link to="/backers" className="link-text color-blue transition-to-white  bakers-bold">
              {translated('app_copy.backers_title')}
            </Link>
            <Link to="/leadership" className="link-text color-blue transition-to-white  lead-bold">
              {translated('app_copy.leadership_title')}
            </Link>
            <Link to="/who-we-serve" className="link-text color-blue transition-to-white  who-bold">
              {translated('app_copy.who_we_serve')}
            </Link>
            <Link to="/work-with-us" className="link-text color-blue transition-to-white  work-bold">
              {translated('app_copy.work_with_us')}
            </Link>

          </div>
        </div>


      </header>
    </div>
    </div>
  );
}
