import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';
import SimpleBar from 'simplebar-react';

const WhoWeServe: React.FC = () => {
  const { translated } = useContext(AppContext)
  const [isLoader, setIsLoader] = useState(true);
  let { state } = useLocation();

  useEffect(() => {
    if (['provider-and-clinic','digital-health-provider','health-plans'].includes(state?.prevPage)) {      
        document.body.className = "who-pg no-animation";       
    } else {
      document.body.className = "who-pg";     
    }
  }, []);


  useEffect(() => {
    $(document).ready(function () {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    });
  }, []);
  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={!isLoader} minTime={2000} scale={0.25} className="normal-page-loader">
          <Helmet>
            <title>Claire, Who We Serve.</title>
            <meta name="description" content="Claire, Who We Serve." />
          </Helmet>
          <div className="shadow-fixed"></div>
          <div className="d-flex-center h100">
            <div className="d-flex-center h100 title-work">
              <h2 className="big-title-light color-blue text-animation  fadeInBottom ">{translated('app_copy.who_we_serve')}</h2>
              <p className="body-text color-black mt-1 text-align-center text-animation fadeInBottom sequence2">
                {translated('app_copy.who_we_serve_des')}
              </p>
              <div className="jobs-wrapper desk-only">
                <div className={'job-item text-animation fadeInBottom sequence3'}>
                  <div className="border-left mt-1 job-title">
                    <p className="medium-title">{translated('app_copy.provider_and_clinic')}</p>
                  </div>
                  <Link to='/provider-and-clinic' className="view-job-cta link-text bold color-blue">
                    {translated('cta.more')}
                  </Link>
                </div>
                <div className={'job-item text-animation fadeInBottom sequence4'}>
                  <div className="border-left mt-1 job-title">
                    <p className="medium-title">{translated('app_copy.digital_health_provider')}</p>
                  </div>
                  <Link to='/digital-health-provider' className="view-job-cta link-text bold color-blue">
                    {translated('cta.more')}
                  </Link>
                </div>
                <div className={'job-item text-animation fadeInBottom sequence5'}>

                  <div className="border-left mt-1 job-title">
                    <p className="medium-title">{translated('app_copy.health_plans')}</p>
                  </div>
                  <Link to='/health-plans' className="view-job-cta link-text bold color-blue">
                    {translated('cta.more')}
                  </Link>
                </div>
              </div>
              <div className="jobs-wrapper mob-only">
                <SimpleBar style={{ width: '100%', height: '100%' }} autoHide={false}>
                  <div className={'job-item text-animation fadeInBottom sequence3'}>
                    <div className="border-left mt-1 job-title">
                      <p className="medium-title">{translated('app_copy.provider_and_clinic')}</p>
                    </div>
                    <Link to='/provider-and-clinic' className="view-job-cta link-text bold color-blue">
                      {translated('cta.more')}
                    </Link>
                  </div>
                  <div className={'job-item text-animation fadeInBottom sequence4'}>
                    <div className="border-left mt-1 job-title">
                      <p className="medium-title">{translated('app_copy.digital_health_provider')}</p>
                    </div>
                    <Link to='/digital-health-provider' className="view-job-cta link-text bold color-blue">
                      {translated('cta.more')}
                    </Link>
                  </div>
                  <div className={'job-item text-animation fadeInBottom sequence5'}>
                    <div className="border-left mt-1 job-title">
                      <p className="medium-title">{translated('app_copy.health_plans')}</p>
                    </div>
                    <Link to='/health-plans' className="view-job-cta link-text bold color-blue">
                      {translated('cta.more')}
                    </Link>
                  </div>
                </SimpleBar>
              </div>
            </div>
          </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default WhoWeServe;

