import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";
import { getJobs } from '@/services/firebase/fireStore';
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import Spinner from '@/components/common/Spinner';
import { Accordion } from 'react-bootstrap';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';

const PrivacyPolicy: React.FC = () => {
  const { translated,ClaireLoader } = useContext(AppContext);
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    $(document).ready(function () {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    });
  });

  useEffect(() => {
    document.body.className = "privacy-policy";
    return () => {
      document.body.className = "";
    };
  }, []);
  return (
    <>
      <Pages>
          <Helmet>
            <title>Claire, Privacy Policy.</title>
            <meta name="description" content="Claire, Privacy Policy." />
          </Helmet>
          <div className="shadow-fixed"></div>
          <div className="d-flex-center h100">
            <div className="d-flex-center h100 terms">
              <h2 className="big-title-light color-blue text-animation  fadeInBottom ">
                {translated('app_copy.privacy_policy')}
              </h2>
            
              <div className="terms-cnt">
                <div className="accordion body-text color-black mt-2 text-align-center text-animation fadeInBottom sequence2" id="accordionPanel">
                  { translated('app_copy.privacy_policy_description') }
                </div>
              </div>
            </div>
          </div>
      </Pages>
    </>
  );
};

export default PrivacyPolicy;
