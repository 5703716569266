import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link, useLocation } from "react-router-dom";
import { getLeadership } from '@/services/firebase/fireStore';
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import { storageDownloadURL } from '@/services/firebase/fireStorage';
import SimpleBar from 'simplebar-react';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';

const Leadership: React.FC = () => {
  const {translated } = useContext(AppContext)
  const [leadership, setLeadership] = useState<any[]>([]);
  const fetchCalled = useRef(false);
  const [isLoader, setIsLoader] = useState(true);
  let { state } = useLocation();

  useEffect(() => {
    document.body.className = "leaderships-pg";
    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    if (state?.back &&  state?.back === 'from-details-page') {      
        document.body.className = "leaderships-pg no-animation";       
    } else {
      document.body.className = "leaderships-pg";     
    }
  });

  const dataFormatting =async (leadershipData:any) => {    
        
        if(leadershipData) {
          const keys = Object.keys(leadershipData);
          const profiles: string[] = [];
          const profileInfo:any = await new Promise((resolve, reject) =>{
            let count = 0;
            keys.forEach(async (key) => {
              let profile = leadershipData[key];
              let profile_img = loadProfileImg(key);
              profile['profile_img'] = await profile_img;
              profile['url'] = '/leadership/'+key;
              profiles[profile.display_order] = leadershipData[key];
              count++;
              //console.log(keys.length, profiles, count);
              
              if(keys.length === count){
                resolve(true)
              }
            });           
        })
          //console.log(profileInfo);  
          if(profileInfo === true){
            setLeadership(leadership => profiles);
          }    
        } 
        setIsLoader(false);
      
  }  

  const fetchLeadership = async () => {
    const data = await getLeadership();
    if(data){
    const leadershipData = data.data();
    localStorage.setItem('LeadershipData', JSON.stringify(leadershipData));
    dataFormatting(leadershipData);}
     /*  if(data) {
        const leadershipData = data.data();
        if(leadershipData) {
          const keys = Object.keys(leadershipData);
          const profiles: string[] = [];
          const profileInfo:any = await new Promise((resolve, reject) =>{
            let count = 0;
            keys.forEach(async (key) => {
              let profile = leadershipData[key];
              let profile_img = loadProfileImg(key);
              profile['profile_img'] = await profile_img;
              profile['url'] = '/leadership/'+key;
              profiles[profile.display_order] = leadershipData[key];
              count++;
              //console.log(keys.length, profiles, count);
              
              if(keys.length === count){
                resolve(true)
              }
            });           
        })
          //console.log(profileInfo);  
          if(profileInfo === true){
            setLeadership(leadership => profiles);
          }    
        } 
        setIsLoader(false);
      } */
  };

  const loadProfileImg = async (key:any) => {
    return await storageDownloadURL('leadership/profile_img/'+key.trim()+'.png');
  };

  useEffect(() => {
    $(document).ready(function () {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    });
    if(fetchCalled.current === false){
      fetchCalled.current = true;
      const leadeshipLocalData = localStorage.getItem('LeadershipData');
      localStorage.setItem('LeadershipData', '');
      // console.log({state});      
      if(leadeshipLocalData && state?.back &&  state?.back === 'from-details-page')
        dataFormatting(JSON.parse(leadeshipLocalData))
      else
        fetchLeadership();
      //console.log('call getLeadership()');
    }
  },[]);
  //console.log(leadership);
  
  console.log({state});
  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={isLoader} minTime={2000} scale={0.25} className="normal-page-loader">            
          <Helmet>
            <title>Claire, leadership.</title>
            <meta name="description" content="Claire, work with us." />
          </Helmet>
          <div className="shadow-fixed"></div>
          <div className="d-flex-center h100">
            <div className="d-flex-center h100 leadership">
              <h2 className="big-title-light color-blue text-animation  fadeInBottom ">{translated('app_copy.leadership_title')}</h2>
              <p className="body-text color-black mt-1 text-align-center title-wrapper text-animation  fadeInBottom sequence2">
                {translated('app_copy.leadership_description')}
              </p>
              <div className="leader-wrapper mob-only">
              <SimpleBar style={{ width: '100%', height:'100%' }} autoHide={false}>
                {
                  leadership && leadership.map((leader:any, index) => {
                    return (
                      <div className={"leader-cnt text-animation fadeInBottom sequence"+(2+index)} key={index}>
                        <div className="leader-logo">
                          <img src={leader.profile_img}></img>
                        </div>
                        <span className="body-text color-black">{leader.designation}</span>
                        <div className="border-left mt-1 job-title">
                          <p className="medium-title" dangerouslySetInnerHTML={{__html:leader.name}} ></p>

                        </div>
                        <Link to={leader?.url} className="view-job-cta link-text bold color-blue">
                          {translated('cta.view')}
                        </Link>
                      </div>
                    );
                  })
                }
  </SimpleBar>
              </div>
              
              <div className="leader-wrapper desk-only">
             
                {
                  leadership && leadership.map((leader:any, index) => {
                    return (
                      <div className={"leader-cnt text-animation fadeInBottom sequence"+(2+index)} key={index}>
                        <div className="leader-logo">
                          <img src={leader.profile_img}></img>
                        </div>
                        <span className="body-text color-black">{leader.designation}</span>
                        <div className="border-left mt-1 job-title">
                          <p className="medium-title" dangerouslySetInnerHTML={{__html:leader.name}} ></p>

                        </div>
                        <Link to={leader?.url} className="view-job-cta link-text bold color-blue">
                          {translated('cta.view')}
                        </Link>
                      </div>
                    );
                  })
                }

              </div>
            


              
            </div>
          </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default Leadership;

