
/* export const firebaseConfig = {
    // apiKey: "AIzaSyDVoEoj5MF7POjBktO8iusBCDW_ny5mzv8",
    // authDomain: "test-rnd-73136.firebaseapp.com",
    // databaseURL: "https://test-rnd-73136-default-rtdb.firebaseio.com",
    // projectId: "test-rnd-73136",
    // storageBucket: "test-rnd-73136.appspot.com",
    // messagingSenderId: "995302879241",
    // appId: "1:995302879241:web:70d8c6cafba895166bc8d8",
    // measurementId: "G-T73CRPVS4M"
    apiKey: window._env_.REACT_APP_FIREBASE_API_KEY,
    authDomain: window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: window._env_.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: window._env_.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window._env_.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: window._env_.REACT_APP_FIREBASE_APP_ID,
    measurementId: window._env_.REACT_APP_FIREBASE_MEASUREMENT_ID
}; */

let firebaseConfig_temp = {};
if(process.env.NODE_ENV === 'test'){
    firebaseConfig_temp = {    
        apiKey:process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
    };
}else{
    firebaseConfig_temp = {    
        apiKey: window._env_.REACT_APP_FIREBASE_API_KEY,
        authDomain: window._env_.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: window._env_.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: window._env_.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: window._env_.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: window._env_.REACT_APP_FIREBASE_APP_ID,
        measurementId: window._env_.REACT_APP_FIREBASE_MEASUREMENT_ID
        };
}



export const firebaseConfig = firebaseConfig_temp;