import React from "react";
import './ClaireSpinner.scss';
const ClaireSpinner: React.FC<{
  scale?: number;
  className?: string;
}> = ({ scale = 1, className }) => {
  return (
    <div {...{className}}>
      <div style={{scale:`${scale}`,transform: `translate(-${scale > 0 ?50/scale:50}%,-${scale > 0 ?50/scale:50}%)`}}  className="logo-svg-animation">
      <div className="log-container">
        <div className="left-part">
          <svg width="133" height="200" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.38884 15.0553C5.38884 7.73822 10.2777 1.68868 16.6044 0.824457C16.0293 0.766843 15.3966 0.709229 14.8214 0.709229C6.88425 0.709229 0.5 7.16207 0.5 15.0553C0.5 23.0061 6.94177 29.4014 14.8214 29.4014C15.4541 29.4014 16.0293 29.3437 16.6044 29.2861C10.2777 28.3643 5.38884 22.3724 5.38884 15.0553Z" fill="#432CD2" />
          </svg>
        </div>
        <div className="right-part">
          <svg width="120" height="240" viewBox="0 0 18 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.8211 2.26844C11.8303 1.28899 8.72445 0.540001 5.6186 0.885689C1.9951 1.23138 0.499695 2.26844 0.499695 2.26844C2.28268 1.92275 3.433 1.92275 3.433 1.92275C8.20681 1.98037 12.1179 3.82404 15.1087 7.51138C15.7745 8.28097 16.3035 9.18763 16.8176 10.0686C16.8808 10.1769 16.9437 10.2849 17.0067 10.3921C17.1217 10.5073 17.1793 10.6226 17.2368 10.7378C17.2475 10.7485 17.2582 10.7613 17.2696 10.7749C17.3197 10.8343 17.3839 10.9107 17.5244 10.9107C17.5819 10.9107 17.6969 10.9106 17.7544 10.853C17.8945 10.7595 17.883 10.552 17.8736 10.3848C17.8715 10.346 17.8695 10.3094 17.8695 10.2769C17.8407 9.23919 17.8119 8.18707 17.7831 7.13496C17.7544 6.08414 17.7256 5.03332 17.6969 3.99688C17.6394 3.6512 17.5819 3.47835 17.2368 3.30551C16.9684 3.19028 16.7064 3.06865 16.4443 2.94702C15.9203 2.70375 15.3963 2.46049 14.8211 2.26844ZM14.8211 34.0146C11.8303 34.9941 8.72445 35.743 5.6186 35.3974C2.60311 35.0617 1.06145 34.2872 0.628994 34.0699C0.581252 34.0459 0.547029 34.0287 0.525832 34.0197C2.29392 34.3603 3.433 34.3603 3.433 34.3603C8.20681 34.3027 12.1179 32.459 15.1087 28.7717C15.7745 28.0021 16.3035 27.0954 16.8176 26.2144C16.8808 26.1061 16.9437 25.9982 17.0067 25.8909C17.1217 25.7757 17.1793 25.6605 17.2368 25.5452C17.2475 25.5345 17.2582 25.5218 17.2696 25.5082C17.3197 25.4487 17.3839 25.3724 17.5244 25.3724C17.5819 25.3724 17.6969 25.3724 17.7544 25.43C17.8945 25.5236 17.883 25.731 17.8736 25.8983C17.8715 25.937 17.8695 25.9736 17.8695 26.0062C17.8407 27.0439 17.8119 28.096 17.7831 29.1481C17.7544 30.1989 17.7256 31.2497 17.6969 32.2862C17.6394 32.6319 17.5819 32.8047 17.2368 32.9775C16.9684 33.0928 16.7064 33.2144 16.4443 33.336C15.9203 33.5793 15.3963 33.8226 14.8211 34.0146ZM0.499695 34.0146C0.499695 34.0102 0.508315 34.0122 0.525832 34.0197C0.517135 34.018 0.508422 34.0163 0.499695 34.0146Z" fill="#432CD2" />
          </svg>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ClaireSpinner;
