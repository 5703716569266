import React, { useEffect, useRef, useState } from "react";
import ClaireSpinner from "./ClaireSpinner";

const ClaireSpinnerTimer: React.FC<{
  children?: any;
  scale?: number;
  className?: string;
  showSpinner: boolean;
  minTime?: number;
}> = ({
  children,
  scale = 1,
  className = "",
  showSpinner = true,
  minTime = 20000,
}) => {
  const [spinnerTimeOut, setSpinnerTimeOut] = useState<boolean>(false);
  const initTimeout = useRef(false);
  useEffect(() => {
    if (!initTimeout.current) {
      initTimeout.current = true;
      setTimeout(() => {
        //console.log({ spinnerTimeOut });
        setSpinnerTimeOut(true);
      }, minTime);
    }
  });
  return (
    <>
      {!spinnerTimeOut || showSpinner ? (
        <div className="min-time-claire-spinner">
          <ClaireSpinner {...{ scale, className }} />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ClaireSpinnerTimer;
