import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";
import { getJobs } from '@/services/firebase/fireStore';
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import Spinner from '@/components/common/Spinner';
import SimpleBar from 'simplebar-react';
import {
  pasaca,
  liveperson
} from "@/config/svg";

const Backers: React.FC = () => {
  useEffect(() => {
    document.body.className = "backers-pg";
    return () => {
      document.body.className = "";
    };
  }, []);
  const { translated, ClaireLoader } = useContext(AppContext)
  useEffect(() => {
    $('html,body').animate({ scrollTop: 0 }, "fast");
  })
  return (
    <>
      <Pages>
        <Helmet>
          <title>Claire, backers.</title>
          <meta name="description" content="Claire, backers." />
        </Helmet>
        <div className="shadow-fixed"></div>
        <div className="d-flex-center h100 backers">
          <div className="d-flex-center h100">
            <h2 className="big-title-light color-blue text-animation   fadeInBottom ">{translated('app_copy.backers_title')}</h2>
            <p className="body-text color-black mt-1 text-align-center title-wrapper  text-animation  fadeInBottom sequence2">
              {translated('app_copy.backers_description')}
            </p>
            <div className="backers-wrapper d-flex text-animation  fadeInBottom desk-only ">
             
                <div className="backer-box text-animation  fadeInBottom sequence3">
                  <div className="img-logo pasaca">
                    <img src={pasaca} alt='pasaca'></img>
                  </div>
                  <div className="b-description">
                    <p className="body-text-light">
                      {translated('app_copy.backers_pasaca_description')}
                    </p>
                  </div>
                  <a className="view-backers-cta link-text bold color-blue"
                    target="_blank" rel="noopener noreferrer"
                    href='https://pasacacapital.com/'>
                    {translated('cta.view')}
                  </a>
                </div>
                <div className="backer-box text-animation  fadeInBottom sequence4">
                  <div className="img-logo liveperson">
                    <img src={liveperson} alt='pasaca'></img>
                  </div>
                  <div className="b-description">
                    <p className="body-text-light">
                      {translated('app_copy.backers_liveperson_description')}
                    </p>
                  </div>
                  <a className="view-backers-cta link-text bold color-blue"
                    target="_blank" rel="noopener noreferrer"
                    href='https://www.liveperson.com/'>
                    {translated('cta.view')}
                  </a>
                </div>
              

            </div>

            <div className="backers-wrapper d-flex text-animation  fadeInBottom mob-only ">
              <SimpleBar style={{ width: '100%' }} autoHide={false}>
                <div className="backer-box text-animation  fadeInBottom sequence3">
                  <div className="img-logo pasaca">
                    <img src={pasaca} alt='pasaca'></img>
                  </div>
                  <div className="b-description">
                    <p className="body-text-light">
                      {translated('app_copy.backers_pasaca_description')}
                    </p>
                  </div>
                  <a className="view-backers-cta link-text bold color-blue"
                    target="_blank" rel="noopener noreferrer"
                    href='https://pasacacapital.com/'>
                    {translated('cta.view')}
                  </a>
                </div>
                <div className="backer-box text-animation  fadeInBottom sequence4">
                  <div className="img-logo liveperson">
                    <img src={liveperson} alt='pasaca'></img>
                  </div>
                  <div className="b-description">
                    <p className="body-text-light">
                      {translated('app_copy.backers_liveperson_description')}
                    </p>
                  </div>
                  <a className="view-backers-cta link-text bold color-blue"
                    target="_blank" rel="noopener noreferrer"
                    href='https://www.liveperson.com/'>
                    {translated('cta.view')}
                  </a>
                </div>
              </SimpleBar>

            </div>

          </div>
        </div>
      </Pages>
    </>
  );
};

export default Backers;
