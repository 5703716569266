import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link, useParams } from "react-router-dom";
import { getLeadershipDetails } from '@/services/firebase/fireStore';
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import { storageDownloadURL } from '@/services/firebase/fireStorage';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';

const LeadershipDetails: React.FC = () => {
  const { translated } = useContext(AppContext)  
  const [leadership, setLeadership] = useState<any>();
  //const [experience, setExperience] = useState<any>();
  const fetchCalled = useRef(false);
  const [isLoader, setIsLoader] = useState(true);
  const params = useParams();
  //let leaderData:any = '';

  const fetchLeadershipDetails = async () => {
    const data = await getLeadershipDetails(params.profile_key);
    if (data) {
      //console.log(data);
      let leadership_details = data;
      leadership_details.profile_img = await loadProfileImg(params.profile_key);
      //leaderData = leadership_details?.previous_experience;
      //console.log(leadership_details?.previous_experience);
      //setExperience(Object.keys(leaderData));
      //console.log(Object.keys(leaderData));
      setLeadership({ ...leadership_details });
      setIsLoader(false);
    }
  };

  // const loadCompanyImg = async (name:any) => {
  //   return await storageDownloadURL('leadership/company_img/'+name+'.png');
  // };
  const loadProfileImg = async (key:any) => {
    return await storageDownloadURL('leadership/profile_img/'+key.trim()+'.png');
  };

  useEffect(() => {
    $(document).ready(function () {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    });
    if (fetchCalled.current === false) {
      fetchCalled.current = true;
      fetchLeadershipDetails();
      //leaderData = leadership.previous_experience;
      //console.log('call getLeadership()');
    }
  }, []);

  useEffect(() => {
    document.body.className = "leader-detail";
    return () => {
      document.body.className = "";
    };
  }, []);
  // console.log(leadership?.previous_experience);
  // leadership?.previous_experience?.map((item:any) => {
  //   return console.log(item);
  // });
  
  // experience && experience.forEach((key:any) => {
  //   let profile = leadership?.previous_experience[key];
  //   console.log(profile);
  // });  
  // console.log(experience);         


  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={isLoader} minTime={2000} scale={0.25} className="normal-page-loader">
            <Helmet>
              <title>Claire, leadership details.</title>
              <meta name="description" content="Claire, leadership details." />
            </Helmet>
            <div className="shadow-fixed"></div>
            <div className="d-flex-center h100">
              <div className="d-flex-center h100">
                <h2 className="big-title-light color-blue">{translated('app_copy.leadership_title')}</h2>
                {/*<p className="body-text color-black mt-2 text-align-center ">
                  {translated('app_copy.leadership_description')}
                </p>*/}
                <div className="leadership-wrapper">
                  <div className="leader-detail ">
                    <div className="leader-detail-inner d-flex">
                      <img src={leadership?.profile_img} className="mt-2 profile-img" alt='leader-profile'></img>
                      <div className="lead-infos">
                      <div className="leader-name">
                        <span className="body-text color-blue">{leadership?.designation}</span>
                        <p className="medium-title" dangerouslySetInnerHTML={{__html:leadership?.name}}></p>
                      </div>
                      <div className="lead-detail-desc">
                        <p className="body-text" dangerouslySetInnerHTML={{__html:leadership?.details}}></p>
                      </div>
                      </div>                     
                    </div>
                    <div className="d-flex logos">
                      {
                         leadership?.previous_experience && leadership?.previous_experience.map((value:any, key:any) => {
                          return (
                            <><span className='' key={key}>{value}</span><div className='purple-separator'></div></>
                          );
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default LeadershipDetails;
