/* eslint-disable @typescript-eslint/no-unused-vars */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";
import { firebaseConfig } from "@/config/index";
import { getDatabase, ref, DataSnapshot } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";
const firebaseApp = initializeApp(firebaseConfig);

let messaging:any = {};
const testMode : boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'test';
if(!testMode && Boolean(navigator?.serviceWorker)){
  messaging = getMessaging(firebaseApp);
  setTimeout(() => {
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(Boolean(window?._env_?.REACT_APP_RECAPTCHA_SITE_KEY) ? window._env_.REACT_APP_RECAPTCHA_SITE_KEY : process.env.REACT_APP_RECAPTCHA_SITE_KEY),
      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true
    });
  }, 1000)
} 


const auth = getAuth(firebaseApp); // For Authentication
const fireStore = getFirestore(firebaseApp); // For Using Database
const analytics = getAnalytics(firebaseApp);
const dbRef = ref(getDatabase());
const realtimeDB = (name: string) => ref(getDatabase(), name);

export { auth, fireStore, dbRef, realtimeDB, DataSnapshot, messaging };