import { child, get, getDatabase, ref, remove, set } from "firebase/database";
import { dbRef } from './index'
export const getRTDBData = async (collectionName: string) => {
    return await get(child(dbRef, `${collectionName}`)).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        } else {
            return ''
        }
    }).catch((error) => {
        return error
    });
}
