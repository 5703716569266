/* eslint-disable react-hooks/exhaustive-deps */
import Header from "./Header";
import Footer from "./Footer";
import { ProviderProps } from "../interface/type";
import { useContext, useState } from "react";
import { Spinner } from "../components";
import { useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Link } from "react-router-dom";
import {
  back,mute,unmute
} from "@/config/svg";
import { AppContext } from '@/context/createContext';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';

const Pages = ({ children }: ProviderProps) => {
  const { translated,ClaireLoader, authUser, videoUrl, videoUrlMobile } = useContext(AppContext);

  const [loader, setLoader] = useState<boolean>(false);
  let location = useLocation();
  const [mutedBtnText, setMutedBtnText] = useState("Unmute");

  const unmuteVideo = () => {
    const video = document.getElementById('video-js') as HTMLVideoElement;
    video.muted = !video.muted;
    if (video.muted) {
      setMutedBtnText("Unmute");
    }
    else {
      setMutedBtnText("Mute")
    }
  }
  
  const privacyAlertElement = window.document.getElementById('lanyard_root') as any;
  //console.log(privacyAlertElement,privacyAlertElement?.innerHTML.length);
  if(privacyAlertElement?.innerHTML.length !== 0 && location.pathname !== "/") {
    privacyAlertElement.style.display = 'block';
  }

  return (
    <>
        <Header />
        <ToastContainer />
        
        <div className="page-wrapper">
            <Link to="/leadership" state={{ back: "from-details-page" }} className="link-text color-black leader">
              <div className="back "><img src={back} alt='back'></img>
                <p>{translated('cta.back')}</p>
              </div>
            </Link>
        
            {children}
        </div>
        
        <Footer />
    </>
  );
};

export default Pages;