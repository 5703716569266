/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { Suspense, useEffect, useReducer, useState } from "react";
import { AppContext } from "@/context/createContext";
import { AppState } from "@/interface/index";
import { appReducer } from "@/context/reducer/appReducer";
import { ProviderProps } from "@/interface/type";
import { auth, realtimeDB, messaging } from "@/services/firebase";
import { onValue } from "firebase/database";
import {
  getIdToken,
  onAuthStateChanged,
} from "firebase/auth";
import { authentication } from "@/services/firebase/authentication";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "../../translations/config";
import * as RTdb from "@/services/firebase/realtimeDB"
import parse from 'html-react-parser';
import { storageDownloadURL } from '@/services/firebase/fireStorage';
import { claireLoaderImageBlue } from '@/config/svg';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';
//import {getConfigurableText} from '@/services/firebase/fireStore';

const INITIAL_STATE: AppState = {
  user:null,
  wpCategories: []
};

export const AppContextProvider = ({ children }: ProviderProps) => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const uid = localStorage.getItem("uid") as string;
  const [configState, setConfigState] = useState<any>({
    live_engage: null,
    places_key: null,
    stripe: null,
    timekit: null,
  });
  const [settingsState, setSettingsState] = useState<any>({
    app_config: null,
  });
  const [stepModules, setStepModules] = useState<any>({
    onboarding_questionnaire: null,
  });
  const [userInfo, setUserInfo] = useState<any>({
    user_data: null,
    address: null,
    device_location: null,
    cart: null,
    orders: null,
    shcedule_list: null,
    non_essential: null,
    notification_preferences: null
  });
  let messagetimeout: any = false;
  // firebase message part
  const [mainLoader, setMainLoader] = useState(false);
  const [remainingLoaderTime, setRemainingLoaderTime] = useState(false);
  const [isLanguageLoaded, setisLanguageLoaded] = useState(false);

 useEffect(() => {
    //console.log(auth?.currentUser);
    auth?.currentUser?.getIdToken().then((token) => {
      if (token !== (localStorage.getItem("token") as string)) {
        localStorage.setItem("token", token);
      }
    });
    if (!userId || !token) {
      newAnonymousUser();
    }
    if (userId) {
      if (uid !== undefined && userId !== uid) {
        localStorage.removeItem("authToken");
      }

      if (userId !== sessionStorage.getItem("authUId")) {
        sessionStorage.setItem("authUId", userId);
        localStorage.setItem("uid", userId);
      }
    }
  }, [userId, onValue]);

  useEffect(()=>{  
    //console.log({userId});
      
    if(!isLanguageLoaded && userId ){
      //const languageData = getConfigurableText('configurable_text');
      const languageData = RTdb.getRTDBData('configurable_text');
      languageData.then(async (value:any)=>{
        setMainLoader(true)
          //console.log({language:i18next.language});
          if(await value){
            for (const [lang, trans] of Object.entries(value)) {
              //console.log({typeoftrans: typeof trans});
              
              const transValue = await trans;
              const newTrans = traceAndReplace(transValue,"\\n","<br/>");
              
              i18next.addResourceBundle(lang,'translation',newTrans ,true,true)
            }
          }
          //i18next.addResourceBundle('en','translation',value.en ,true,true)
          setisLanguageLoaded(true) 
        }) 
    }
  },[userId])

  const [videoUrl, setVideoUrl] = useState('');
  const fetchVideo = async () => {
    if(userId) {
      const url = await storageDownloadURL('video/claire-hp.mp4');
      if (url) {
        setVideoUrl(url);
      }
    }
  };
  useEffect(() => {
    fetchVideo();
  },[userId]);

  const [videoUrlMobile, setVideoUrlMobile] = useState('');
  const fetchVideoMobile = async () => {
    if(userId) {
      const url = await storageDownloadURL('video/claire-hp-mobile.mp4');
      if (url) {
        setVideoUrlMobile(url);
      }
    }
  };
  useEffect(() => {
    fetchVideoMobile();
  },[userId]);

  const [state, dispatch] = useReducer(appReducer, INITIAL_STATE);

  const setRootLoader = () =>{
    setMainLoader(true);
    setRemainingLoaderTime(true)

    setTimeout(() =>{
      setRemainingLoaderTime(false)
    }, 3000)
  }

  const traceAndReplace = (element:any, search:string[]|string|null = null, replace:string[]|string|null = null):any =>{
      if(typeof element === 'string')
      return searchAndReplace(element, search, replace);
      if(typeof element === 'object'){
        const newElement:any = {};
        const keys = Object.keys(element);
        for (let index = 0; index < keys.length; index++) {
           newElement[keys[index]] = traceAndReplace(element[keys[index]], search, replace);;
          
        }
        return newElement;
      }
  }
  
  /** Translate with dynamic place holder value */

   /**
   * searchAndReplace 
   * This function takes a text with search and replace data. The function modify input text with given search and replace value. 
   * @param {string} key text to modify.
   * @param {string[]|string|null} search search string or array of string.
   * @param {string[]|string|null} replace replace string or array of string.
   * @returns {string} Return the modified string
   */

  const searchAndReplace = (text:string,search:string[]|string|null = null ,replace:string[]|string|null = null):string=>{
    if(search === null || replace === null)
      return text;
    if(typeof search !== typeof replace)
      return text;
    if(typeof search === 'string')
      return text.replaceAll(search as string,replace as string);
    if(search[0] === undefined || replace[0] === undefined)
      return text;

    let newText  = text;
    for (let index = 0; index < search.length; index++) {
      if(replace[index] !== undefined)
      newText = newText.replaceAll(search[index] as string,replace[index] as string);
      
    }

    return newText;
    
  }
 /**
   * translateDynamic 
   * This function takes a translation key with search and replace data. The function first translate the key with and then replace the dynamic values according to search. 
   * @param {string} key Translation key.
   * @param {string[]|string|null} search search string or array of string.
   * @param {string[]|string|null} replace replace string or array of string.
   * @returns {string} Return the modified string
   */
  const translateDynamic = (key:string,search:string[]|string|null ,replace:string[]|string|null)=>{
    return searchAndReplace(t(key),search,replace);
  }

  /** Showing popup success or error messages */
  const showToastMessage = (
    message: string,
    isError: boolean = false,
    options: any
  ) => {
    // const date = new Date();
    // console.log(`TOAST -- message: ${message}, time:${date.toUTCString()}`);
    // toast.clearWaitingQueue();
    if (isError) {
      toast.error(message, { limit: 1, toastId: "error-toast", ...options });
    } else {
      toast.success(message, {
        limit: 1,
        toastId: "success-toast",
        ...options,
      });
    }
  };

  const setMaxRootLoader = () =>{
    setMainLoader(true);
    setTimeout(() =>{
      setMainLoader(false);
      setRemainingLoaderTime(false)
    }, 5000)
  }

  const setMinRootLoader = () =>{
    setMainLoader(true);
    setTimeout(() =>{
      setMainLoader(false);
      setRemainingLoaderTime(false)
    }, 3000)
  }

  const unSetRootLoader = () =>{
    setMainLoader(false);
  }

  const updateTranslationLanguage = (lang:string) =>{
    i18next.changeLanguage(lang)
  }

  const newAnonymousUser = async () => {
    await authentication.signInAnonymously();
    return onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user?.uid);
        setToken(await getIdToken(user));
      }
    });
  }; 

  const addCategory = (item: any) => {
    dispatch({ type: "ADD_CATEGORY", payload: item });
  };
 
  const translateAndParse = (key:string,search?:string[]|string|null,replace?:string[]|string|null):any=>{
    if(search !== undefined && replace !== undefined)
    return parse(translateDynamic(key,search,replace))
    return parse(t(key));
  }

  const ClaireLoader = (props:any):any =>{
    return (
      <Suspense fallback={"Loading..."} >
        <div className="claire-loader">
          <div className="d-flex justify-content-center w-100 h-100 mt-1x page-center align-items-center">
            <img {...props} src={claireLoaderImageBlue} alt="Loading..." />
          </div>        
        </div>
      </Suspense>      
    )
  }

  const providerValue = {
    state,
    authUser: {
      authUId: userId,
      authToken: token,
      userInfo: userInfo,
    },
    configState,
    settingsState,
    stepModules,
    remainingLoaderTime,
    showToastMessage,
    setRootLoader,
    setMinRootLoader,
    setMaxRootLoader,
    unSetRootLoader,
    translated: translateAndParse,
    searchAndReplace,
    translateDynamic,
    updateTranslationLanguage ,
    addCategory,
    newAnonymousUser,
    mainLoader,
    videoUrl,
    videoUrlMobile,
    ClaireLoader,
    ClaireSpinner    
  };
  return (
    <>
      <AppContext.Provider value={providerValue}>
      {/* {!isLanguageLoaded ? (
          <ClaireSpinner scale={0.25} className="normal-page-loader" />
        ) : (
          children
        )} */}
        <ClaireSpinnerTimer showSpinner={!isLanguageLoaded} minTime={2000} scale={0.25} className="normal-page-loader">
            {isLanguageLoaded && children}
        </ClaireSpinnerTimer>
      </AppContext.Provider>
    </>
  );
};
