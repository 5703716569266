import { AppState } from '@/interface/index'

type AppAction =
    { type: 'ADD_CATEGORY', payload: any } ;


export const appReducer = (state: AppState, action: AppAction): AppState => {
    switch (action.type) {
        
        case "ADD_CATEGORY":
            return {
                ...state,
                wpCategories: [...action.payload],
            };
        default:
            return state;
    }
}