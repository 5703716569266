/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import Pages from "@/layouts/Pages";
//import { authentication } from '@/services/firebase/authentication';
import { isSubscribed, unSubscribe } from '@/services/firebase/fireStore';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { Helmet } from "react-helmet";
import { AppContext } from '@/context/createContext';
import { unsubscribe as mailUnSubscribe } from '@/services/api/fireFunction';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';

const Unsubscribe: React.FC = () => {
  const { translated,ClaireLoader } = useContext(AppContext);
  let queryParams = new URLSearchParams(window.location.search);
  let uid = queryParams.get('uid');
  let email = queryParams.get('email');
  const [errorMsg, setErrorMsg] = useState('');
  const [resultMsg, setResultMsg] = useState('');
  const [isMiniLoader, setIsMiniLoader] = useState(false);
  const [className, setClassName] = useState("field_wrapper");

  const handleClick = async () => {
    setErrorMsg('');
    if (uid?.length && email?.length) {
      // const new_user = await authentication.signInAnonymously() as any; 
      // var user_id = new_user?.user?.uid;
      setIsMiniLoader(true); 
      email = atob(email);
       const unsubscription = await mailUnSubscribe({email, uid}) as any;
      if(unsubscription?.data?.success?.status) {        
          setClassName("hide");
          setResultMsg(translated('success.successfully_unsubscribed'));
          setIsMiniLoader(false);
      }
      else if(!unsubscription?.data?.error?.status) {        
          setErrorMsg(translated('error.can_not_unsubscribe'));
          setIsMiniLoader(false);
      }
    }
    else {
      setErrorMsg(translated('error.something_wrong'));
    }
  }

  return (
    <>
      <Pages>
        <Helmet>
          <title>Claire, Home of home base testing.</title>
          <meta name="description" content="Claire, Home of home base testing." />
        </Helmet>
        <section className="h-100 h-custom login">
          <div className="d-flex-center h100">
            <div className="d-flex-center h100">
              <h2 className="big-title color-blue">{translated('app_copy.are_you_leaving') }</h2>
              <p className="body-text color-black mt-2 mb-3 text-align-center">
                {translated('app_copy.are_you_sure') }
              </p>
              <div className={'d-flex-center w100 ' + className}>
                <button id="unsubscriptionBtn" className="primary-cta" onClick={(e) => handleClick()}>
                  <span className="gradient-text bold">                  
                    { translated('cta.unsubscribe') }
                  </span>
                </button>
              </div>
              {
                errorMsg && 
                  <p className="error_msg blue-background m-1 color-red">{ errorMsg}</p>
              }
              {
                isMiniLoader ? (<ClaireSpinner scale={0.25} className="normal-page-loader" />) : <></>
              }
              {
                resultMsg &&
                <p className="result_msg blue-background m-1 color-blue">{ resultMsg}</p>
              }
            </div>
          </div>
        </section>
      </Pages>
    </>
  );
};

export default Unsubscribe;
