import { Outlet } from "react-router-dom";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const MasterContent: React.FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  let location = useLocation();

  return (
    <>
      {!loader && (
        <div className={"master-content pos-relative light-background h100vh " + (location.pathname === "/" ? "home" : "") + (location.pathname === "/job-detail" ? "job-detail" : "")}>
        
          <Outlet />
        </div>
      )}
    </>
  );
};

export default MasterContent;
