import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";
import { getJobs, settings } from '@/services/firebase/fireStore';
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import Spinner from '@/components/common/Spinner';
import { locationAPI } from '@/services/api/api';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';
import SimpleBar from 'simplebar-react';

const WorkWithUs: React.FC = () => {
  const {translated,ClaireLoader } = useContext(AppContext)
  const [jobs, setJobs] = useState<any[]>([]);
  const [location, setLocation] = useState<any>();
  const fetchCalled = useRef(false);
  const [isLoader, setIsLoader] = useState(true);
  const [settingData, setSettingData] = useState<any>();

  const fetchJobs = async () => {
    const data = await getJobs();
      if(data) {
        data.docs.forEach(item => {
          let job = item.data();
          var currentSeconds = new Date().getTime() / 1000;
          if(job.expire_date.seconds > currentSeconds) {            
            setJobs(jobs => [...jobs, {id:'/job/'+item.id, ...job}]);
          }
        });
        setIsLoader(false);
      }
  };
  useEffect(() => {
    document.body.className = "work-pg";
    return () => {
      document.body.className = "";
    };
  }, []);
  
  const loadSettings = async () => {
    //console.log('loadSettings');
    //setIsMiniLoader(true);
    const data = await settings();
    if(data) {
      //console.log('settings data:', data);
      setSettingData({...data.data()});
    }
  };
  const locationData = async (setting_data:any) => {
    const location_api_url = setting_data?.location_api?.path;
    const data:any = await locationAPI(location_api_url);
    //console.log(data?.data?.data);
    if(data) {
       setLocation({...data?.data?.data});
    }
  }

  useEffect(() => {
    $(document).ready(function () {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    });
    if(fetchCalled.current === false){
      fetchCalled.current = true;
      loadSettings();
      fetchJobs();
      //console.log('call getJobs()');
    }
  }, [settingData]);

  useEffect(() => {
    settingData && locationData(settingData);
  }, [settingData]);

  useEffect(() => {
    if (window.location.href.indexOf('?back') > -1) {
      document.body.className = "work-pg no-animation";
    } else {
      document.body.className = "work-pg";
    }
  });

  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={isLoader} minTime={2000} scale={0.25} className="normal-page-loader">
            <Helmet>
                <title>Claire, work with us.</title>
                <meta name="description" content="Claire, work with us." />
            </Helmet>
            <div className="shadow-fixed"></div>
            <div className="d-flex-center h100">
              <div className="d-flex-center h100 title-work">
                <h2 className="big-title-light color-blue text-animation  fadeInBottom ">{translated('app_copy.work_with_us')}</h2>          
                  <p className="body-text color-black mt-1 text-align-center text-animation fadeInBottom sequence2">
                    { translated('app_copy.we_dont_want_add_noise') }
                  </p>
                  {
                    !settingData?.location_api?.allowed_country.split(',').includes(location?.countryCode) ? 
                      <>
                        <div className='align-items-center  no-job text-animation fadeInBottom sequence3'> 
                          {translated('app_copy.no_job_this_area')}
                        </div>
                      </> :
                      (<>              
                        <div className="jobs-wrapper desk-only">
                       { 
                            jobs && jobs.map((job: any, index:any) => {
                              return (
                                <div className={'job-item text-animation fadeInBottom sequence'+(2+index) + (!("type" in job) ? 'job-type-empty' : '')} key={index}>
                                  <span className="body-text color-black">{job.type}</span>
                                  <div className="border-left mt-1 job-title">
                                    <p className="medium-title">{job.title}</p>
                                  </div>
                                  <Link to={job.id} className="view-job-cta link-text bold color-blue">
                                    { translated('cta.view') }
                                  </Link>
                                </div>
                              );
                            }) 
                          }
                          
                        </div>
                        <div className="jobs-wrapper mob-only">
                        <SimpleBar style={{ width: '100%', height:'100%' }} autoHide={false}>
                          { 
                            jobs && jobs.map((job: any, index:any) => {
                              return (
                                <div className={'job-item text-animation fadeInBottom sequence'+(2+index) + (!("type" in job) ? 'job-type-empty' : '')} key={index}>
                                  <span className="body-text color-black">{job.type}</span>
                                  <div className="border-left mt-1 job-title">
                                    <p className="medium-title">{job.title}</p>
                                  </div>
                                  <Link to={job.id} className="view-job-cta link-text bold color-blue">
                                    { translated('cta.view') }
                                  </Link>
                                </div>
                              );
                            }) 
                          }
                          </SimpleBar>
                        </div>
                      </>
                      )                    
                  }
              </div>
             
            </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default WorkWithUs;

