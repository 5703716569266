import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const storage = getStorage();

export const uploadFileToStorage = (file: any, file_url: string) => {
    const storageRef = ref(storage, file_url);
    const uploadTask = uploadBytesResumable(storageRef, file);
    return uploadTask
}

export const storageDownloadURL = async (path: string) => {
    return await getDownloadURL(ref(storage, path))
        .then((url) => {
            return url;
        })
        .catch((error) => {
            console.log(error);
        });
}