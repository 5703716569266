import React, { useContext, useEffect, useRef, useState } from "react";
import Pages from "@/layouts/Pages";
import { Link, Navigate, redirect, useNavigate } from "react-router-dom";
import { Accordion } from 'react-bootstrap';
import { back, uploadIcon } from "@/config/svg";
import { getJobDetails, isApplied, settings, submitApplication } from '@/services/firebase/fireStore';
import { submitApplication as submitApplicationAPI } from '@/services/api/fireFunction';
import { locationAPI } from '@/services/api/api';
import { useParams } from 'react-router-dom';
import { validateEmail, validateLinkedinUrl, validatePhone, validateUrl } from '@/helper/common';
import { authentication } from '@/services/firebase/authentication';
import {
  uploadFileToStorage
} from "@/services/firebase/fireStorage";
import Spinner from '@/components/common/Spinner';
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';

interface IFormData {
  resume: File,
  cover_letter: File,
  first_name: string,
  last_name: string,
  email: string,
  phone_number: string,
  gender: string,
  veteran_status: string,
  disability_status: string,
  acknowledged: boolean,
  uid: string,
  job_title?: string
  linkedin_profile: string,
  website: string,
  how_hear: string
}

const JobDetails: React.FC = () => {
  const { translated,ClaireLoader } = useContext(AppContext);
  const [jobDetails, setJobDetails] = useState<any>({});
  const [isLoader, setIsLoader] = useState(true);
  const [isMiniLoader, setIsMiniLoader] = useState(false);
  const params = useParams();
  const fetchCalled = useRef(false);
  let resumeFileInput = useRef<any>();
  let coverFileInput = useRef<any>();
  const inputChange = useRef(false);
  const [resumeFileUrl, setResumeFileUrl] = useState<any>();
  const [resumeFileName, setResumeFileName] = useState('');
  const [coverFileUrl, setCoverFileUrl] = useState<any>();
  const [coverFileName, setCoverFileName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [errorMsgs, setErrorMsgs] = useState<any>({});
  const [resultMsg, setResultMsg] = useState('');
  const [disable, setDisable] = useState(false);
  const [clickSubmit, setClickSubmit] = useState(false);
  const [formData, setFormData] = useState({
    resume: '',
    cover_letter: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    gender: '',
    veteran_status: '',
    disability_status: '',
    acknowledged: true,
    uid: 'uid',
    job_title: '',
    linkedin_profile: '',
    website: '',
    how_hear: ''
  });
  const navigate = useNavigate();
  const [resumeUploadFile, setResumeUploadFile] = useState<any>();
  const [coverUploadFile, setCoverUploadFile] = useState<any>();
  let formSubmit = useRef(false);
  //const resumeAllowedSize = 5*1024*1024;
  const [settingData, setSettingData] = useState<any>();
  //let job_key = useRef(false);

  useEffect(() => { 
    document.body.className = "work-pg";
    return () => {
      document.body.className = "";
    };
  }, []);

  const fetchJobDetails = async () => {
    const data = await getJobDetails(params.job_id);
    if(data) {
        //console.log('data:',{...data.data()});
        setJobDetails({...data.data()});
        setFormData({
          ...formData, job_title: params.job_id as string
        });
        setIsLoader(false);
      //console.log(data.docs.length);    
      if(!data.exists()) {
        navigate('/');
      }
    }
  }

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    let inputFileName = event.target.name;
    //console.log('inputFileName:',inputFileName);
    let fileName = file.name.substring(0,20) + "...";
    //console.log(file);
    if(inputFileName === 'resume' && file && resumeFileValidation(file)) {
        const fileObjUrl = URL.createObjectURL(file); 
        //console.log(fileObjUrl);
        const res: Response = await fetch(fileObjUrl);
        const blob: Blob = await res.blob();

        const id = Math.floor(Math.random() * 1000) + '' + Date.now();
        setResumeUploadFile(file);   
        setResumeFileName(fileName);
        setResumeFileUrl(blob);       
        setFormData({
          ...formData,
          resume: id
        });
    } else if(inputFileName === 'cover_letter' && file && coverFileValidation(file)) {
        const fileObjUrl = URL.createObjectURL(file); 
        //console.log(fileObjUrl);
        const res: Response = await fetch(fileObjUrl);
        const blob: Blob = await res.blob();

        const id = Math.floor(Math.random() * 1000) + '' + Date.now();
        setCoverUploadFile(file);   
        setCoverFileName(fileName);
        setCoverFileUrl(blob);                
        setFormData({
          ...formData,
          cover_letter: id
        });
    }
    else 
      event.target.value = '';
      
    inputChange.current = true;
  };

  const resumeHandleClick = () => {
    resumeFileInput.current.click();
  };

  const coverHandleClick = () => {
    coverFileInput.current.click();
  };

  const resumeFileValidation = (file:any) => {
      // Allowing file type
      var allowedExtensions = /(\.pdf)$/i;

      if (!allowedExtensions.exec(file.name)) {
          setDisable(false);
          setErrorMsgs({...errorMsgs, 'resume' : translated('error.file_type_not_allowed') });
          $('.result_msg').fadeOut('slow');
          //event.target.value = '';
          return false;
      }
      else if(file.size > settingData?.resume?.max_size*1024*1024) {        
          setDisable(false);
          setErrorMsgs({...errorMsgs, 'resume' : translated('error.max_upload_size') + ' ' + settingData?.resume?.max_size+'MB.'});
          $('.result_msg').fadeOut('slow');
          return false;
      }
      
      return true;
  }

  const coverFileValidation = (file:any) => {
      // Allowing file type
      var allowedExtensions = /(\.pdf)$/i;

      if (!allowedExtensions.exec(file.name)) {
          setDisable(false);
          setErrorMsgs({...errorMsgs, 'cover_letter' : translated('error.file_type_not_allowed') });
          $('.result_msg').fadeOut('slow');
          //event.target.value = '';
          return false;
      }
      else if(file.size > settingData?.resume?.max_size*1024*1024) {        
          setDisable(false);
          setErrorMsgs({...errorMsgs, 'cover_letter' :translated('error.max_upload_size') + ' ' + settingData?.resume?.max_size+'MB.'});
          $('.result_msg').fadeOut('slow');
          return false;
      }
      
      return true;
  }

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;
    // console.log(event);
    // console.log(event.target.selected);
    // console.log(event.target.selection);
    let inputValue = value; 
    if(type  === 'checkbox') {
      //console.log('2nd:'+type, checked);
      inputValue = checked;
    }
    else if(name === 'phone_number') {
      inputValue = inputValue.replace(/[^0-9+()-+\s]/, "");
    }
      setFormData({
      ...formData,
      [name]: inputValue
    });
  }

  useEffect(() => {
    if(formSubmit.current === true && formData.uid !== 'uid') {
      formSubmit.current = false;
       formSubmitted();
    }
  }, [formData]);

  const formSubmitted = async ()  => {
      const user_id = formData.uid;
      //console.log({formData});
      const hasAPI = settingData?.api;
      if( hasAPI === true) {
        processSubmitAPI();
      }
      else {
        await fileUpload(user_id);
        await processApplicationData(user_id);
      }
  }

  useEffect(() => {
    if(fetchCalled.current === false) {
      fetchCalled.current = true;
      loadSettings();
      fetchJobDetails();
    }
  }, [settingData]);

  useEffect(() => {
    settingData && locationData(settingData);
  }, [settingData]);

  useEffect(() => {
      let errorObject = {};
      if(formData.email && !validateEmail(formData.email)) {
        errorObject = {...errorObject, 'email': translated('error.insert_a_valid_email')};
      }
      else if(validateEmail(formData.email)) {
        errorObject = {...errorObject, 'email': null};
      } 

      if(formData.phone_number && !validatePhone(formData.phone_number)) { 
        errorObject = {...errorObject, 'phone_number': translated('error.insert_a_valid_phone_number')};
      }
      if(validatePhone(formData.phone_number)) { 
        errorObject = {...errorObject, 'phone_number': null};
      } 

      if(formData.linkedin_profile && !validateLinkedinUrl(formData.linkedin_profile)) { 
        errorObject = {...errorObject, 'linkedin_profile': translated('error.insert_a_valid_linkedin_profile')};
      }
      if(validateLinkedinUrl(formData.linkedin_profile)) { 
        errorObject = {...errorObject, 'linkedin_profile': null};
      }   

      if(formData.website && !validateUrl(formData.website)) {
        errorObject = {...errorObject, 'website': translated('error.insert_a_valid_website')};
      }
      if(validateUrl(formData.website)) {
        errorObject = {...errorObject, 'website': null};
      }      

      if(clickSubmit) {        
        if(!formData.first_name) { 
          errorObject = {...errorObject, 'first_name' : translated('error.first_name_required')};
        }
        else {          
          errorObject = {...errorObject, 'first_name' : null};
        }

        if(!formData.last_name) {  
          errorObject = {...errorObject, 'last_name' : translated('error.last_name_required')};
        }
        else {          
          errorObject = {...errorObject, 'last_name' : null};
        }

        if(!formData.phone_number) { 
          errorObject = {...errorObject, 'phone_number': translated('error.insert_a_valid_phone_number')};
        } 
        if(!formData.email) {
          errorObject = {...errorObject, 'email': translated('error.insert_a_valid_email')};
        }
      } 

      if(Object.keys(errorObject).length) {
          setDisable(false); 
          $('.result_msg').fadeOut('slow');
          setErrorMsgs({...errorMsgs,...errorObject});
      }
      // else if(!formData.resume || !formData.cover_letter) {
      //     setDisable(false);
      //     //setErrorMsg(translated('error.resume_not_attached'));
      //     setErrorMsg('');
      //     $('.result_msg').fadeOut('slow');
      // }
      else {
        // if(!formData.first_name) {  
        //   setDisable(false);
        //   setErrorMsgs({...errorMsgs, 'first_name' : translated('error.first_name_required')});
        //   $('.result_msg').fadeOut('slow');
        // }
        // else if(!formData.last_name) {  
        //   setDisable(false);
        //   setErrorMsgs({...errorMsgs, 'last_name' : translated('error.last_name_required')});
        //   $('.result_msg').fadeOut('slow');
        // }
        // else {
        //   setDisable(false);
        //   //setErrorMsg(translated('error.form_validation_failed'));
        //   setErrorMsg('');
        //   $('.result_msg').fadeOut('slow');
        // }  
        // let isValid = isSetInput();
        // console.log('isvalid:', isValid);
        // if(isValid === true) {
        //     setDisable(true);
        //     setErrorMsg('');
        //     $('.result_msg').fadeOut('slow');
        // }
        // else {
        //   setDisable(false);
        //   //setErrorMsg(translated('error.form_validation_failed'));
        //   setErrorMsg('');
        //   $('.result_msg').fadeOut('slow');
        // }  
      }
  }, [formData]);

  const submitData = async () => {
    setClickSubmit(true);
    let errorObject:any = {};
    if(!formData.first_name) { 
      errorObject = {...errorObject, 'first_name' : translated('error.first_name_required')};
    }
    if(!formData.last_name) {  
      errorObject = {...errorObject, 'last_name' : translated('error.last_name_required')};
    }
    if(!formData.phone_number || !validatePhone(formData.phone_number)) {  
      errorObject = {...errorObject, 'phone_number' : translated('error.insert_a_valid_phone_number')};
    }
    if(!formData.email || !validateEmail(formData.email)) { 
      errorObject = {...errorObject, 'email' : translated('error.insert_a_valid_email')};
    }
  
    if(Object.keys(errorObject).length>0) {
      setErrorMsgs({...errorMsgs, ...errorObject}); //
      setDisable(false);
      $('.result_msg').fadeOut('slow');
      const element:any = window.document.getElementById('apply-now-action');
      if(element) {       
        element.click();
      }
    }
    else {      
      setIsMiniLoader(true);
      const new_user = await authentication.signInAnonymously() as any;
      var user_id = new_user?.user?.uid;
      //console.log('user_id:' +user_id);
      
      setFormData({
        ...formData,
        uid: user_id
      });

      formSubmit.current = true;
    }
  }  
  
  const loadSettings = async () => {
    //console.log('loadSettings');
    //setIsMiniLoader(true);
    const data = await settings();
    if(data) {
      //console.log('settings data:', data);
      setSettingData({...data.data()});
    }
  };

  const locationData = async (setting_data:any) => {
    const location_api_url = setting_data?.location_api?.path;
    const location_api_allowed_country = setting_data?.location_api?.allowed_country;
    // console.log('location_api_url', location_api_url);
    // console.log('location_api_allowed_country', location_api_allowed_country);
     const data:any = await locationAPI(location_api_url);
     if(data) {
        let location = data?.data?.data;
        //console.log('location?.countryCode:', location?.countryCode);
        let allowed_country_array = location_api_allowed_country.split(',');
        if(!allowed_country_array.includes(location?.countryCode)){
          navigate('/');
        }
     }
  }

  const processSubmitAPI = async () => {  
    const frmData = new FormData();
    frmData.append('first_name', formData.first_name);
    frmData.append('last_name', formData.last_name);
    frmData.append('email', formData.email);
    frmData.append('phone_number', formData.phone_number);
    frmData.append('linkedin_profile', formData.linkedin_profile);
    frmData.append('website', formData.website);
    frmData.append('how_hear', formData.how_hear);
    frmData.append('gender', formData.gender);
    frmData.append('veteran_status', formData.veteran_status);
    frmData.append('disability_status', formData.disability_status);
    frmData.append('acknowledged', 'true');
    frmData.append('uid', formData.uid);
    frmData.append('job_title', formData.job_title);
    frmData.append('resume', resumeUploadFile);
    frmData.append('cover_letter', coverUploadFile);
     //'resume/'+user_id+'/'+formData.resume
    //console.log('formData.uid:'+formData.uid);
    const new_application = await submitApplicationAPI(frmData) as any;
    //console.log('new_application:', new_application);
    setErrorMsgs('');
    setErrorMsg('');
    const element:any = window.document.getElementById('apply-now-action');
    if (new_application?.data?.success?.status) {
      setResultMsg(translated('success.successfully_submitted'));
      $('.result_msg').fadeIn();
      $('.form').hide()
    }
    else if(!new_application?.data?.error?.status) {
      setErrorMsg(new_application?.data.error.message);
      $('.result_msg').fadeOut('slow');
      // if(element) {        
      //   element.click();
      // }
    }
    else {
      setErrorMsg(translated('error.something_wrong')); 
      // if(element) {        
      //   element.click();
      // }
    }
    //console.log(new_application.status);
    setIsMiniLoader(false);
    return true;
  }

  function isSetInput() {
    return Object.keys(formData).every(function (i:any) {
      //console.log(i + '=>' + formData[i as keyof IFormData]);
      if (formData[i as keyof IFormData] === '' || formData[i as keyof IFormData] === null || formData[i as keyof IFormData] === false) {
        if((i === 'resume' && settingData?.resume?.is_visible && settingData?.resume?.is_required) || (i === 'cover_letter' && settingData?.cover_letter?.is_visible && settingData?.cover_letter?.is_required)) {
          //console.log(i+': false1');
          return false;
        }
        else if(!['resume','cover_letter','phone_number','linkedin_profile','website', 'gender', 'veteran_status', 'disability_status', 'how_hear'].includes(i)) {
          //console.log(i+': false2');
          return false;
        }
        //console.log(i+': else');        
      }
      return true;
    });
  };
  
  const fileUpload = async (user_id:any) => {
    if (formData.resume) {
      const filePath = formData.resume;
      const resumeUploadTask = uploadFileToStorage(resumeFileUrl, 'resume/'+user_id+'/'+filePath);
	  
      resumeUploadTask.on(
        "state_changed",
        async (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //setIsLoader(false);
          // setFileSubmitURL("");
          // setPhotoURL("");
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              //console.log("Upload is paused");
              break;
            case "running":
              //console.log("Upload is running");
              break;
          }

          if (progress === 100) {
            //console.log("Uploaded");
            // storageDownloadURL(resumeFileUrl).then((url: any) => {
            //   // setFileSubmitURL(resumeFileUrl);
            //   // setPhotoURL(url);
            //   setIsLoader(false);
            // });
            //setIsMiniLoader(false);
          }
        },
        (error) => {
          //console.log({error});
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }
      );
    }   
    if (formData.cover_letter) {
      const filePath = formData.cover_letter;
      const coverLetterUploadTask = uploadFileToStorage(coverFileUrl, 'cover_letter/'+user_id+'/'+filePath);
	  
      coverLetterUploadTask.on(
        "state_changed",
        async (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          //setIsLoader(false);
          // setFileSubmitURL("");
          // setPhotoURL("");
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              //console.log("Upload is paused");
              break;
            case "running":
              //console.log("Upload is running");
              break;
          }

          if (progress === 100) {
            //console.log("Uploaded");
            // storageDownloadURL(resumeFileUrl).then((url: any) => {
            //   // setFileSubmitURL(resumeFileUrl);
            //   // setPhotoURL(url);
            //   setIsLoader(false);
            // });
            //setIsMiniLoader(false);
          }
        },
        (error) => {
          //console.log({error});
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }
      );
    }
  };

  async function processApplicationData(user_id:any) {
    //console.log(user_id);
    const hasApplication = await isApplied(params.job_id, formData.email);
    //console.log('hasApplication:', hasApplication);
    if (hasApplication) {
      setErrorMsgs('');
      //const element:any = window.document.getElementById('apply-now-action');
      //console.log('hasApplication.data():', hasApplication.data());
      if (!hasApplication.data()) {
        const new_application = await submitApplication(params.job_id, formData);
        //console.log('new_application:', new_application);
        if (new_application) {
          setResultMsg(translated('success.successfully_submitted'));
          $('.result_msg').fadeIn();
          $('.form').hide()
        }
        else {
          setErrorMsg(translated('error.something_wrong'));
          $('.result_msg').fadeOut('slow');
          // if(element) {        
          //   element.click();
          // }
        }
      }
      else {
        setErrorMsg(translated('error.application_submitted_already'));
        setErrorMsgs({...errorMsgs, 'email': translated('error.application_submitted_already')});
        $('.result_msg').fadeOut('slow');
        // if(element) {     
        //   element.click();
        // }
      }
      setIsMiniLoader(false);
    }
  }

  const clickedApplyNow = () => {    
    const element = window.document.getElementsByClassName('personal_data')[0];//(`id-${eventKey}`)
    //console.log(`{element,id:id-${eventKey}}`);
    const button = element?.getElementsByTagName('button');
    
    if(button !== undefined && button?.length > 0 && button[0].classList.contains('collapsed')){
      button[0].click();
    }
  }
  
  const CustomAccordionItem =({content,eventKey,headerTitle}:{content:any,eventKey:any,headerTitle:string}):any => {
    return (
    <>
        <Accordion.Item eventKey={`${eventKey}`}>
          <Accordion.Header id={`id-${eventKey}`}>
            <span className="accordion-header">{ translated(headerTitle) }</span>
          </Accordion.Header>
          <Accordion.Body>
            <div className='reset-design' 
            dangerouslySetInnerHTML={{__html:content}}></div>
          </Accordion.Body>
        </Accordion.Item>
      </>
    )
  }
  
  // const CustomInputFieldsContainers = ({ children, errorKey }:{ children:any; errorKey:string })=> {  
  //   let cssClass = "input-wrapper mt-15"

  //   if(errorMsgs[errorKey] !== undefined && errorMsgs[errorKey])
  //     cssClass += " has-error";
  
  //   return (<>
  //     <div className={cssClass}>
  //       {children}
  //     </div>
  //     { errorMsgs[errorKey] !== undefined && errorMsgs[errorKey] &&
  //       <p className="error_msg color-red ms-4">{errorMsgs[errorKey]}</p>
  //     }
  //   </>   
  //   );
  // }

  //console.log({errorMsgs});
  //console.log({formData});
  //console.log('clicked:', clicked);
  //console.log(settingData?.resume?.is_visible); 
  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={isLoader} minTime={2000} scale={0.25} className="normal-page-loader">
            <Helmet>
                <title>{jobDetails.title}</title>
                <meta name="description" content="{jobDetails.title}" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
            </Helmet>
            <div className="light-background job-details-wrapper h100">                
                <div className="left-side">
                  <Link to="/work-with-us?back" className="link-text color-black">
                    <img src={back} alt="Back" className="mr-1" />
                    <span className="body-text back-cta">{translated('cta.view_all_jobs')}</span>
                  </Link>
                  <div className="d-flex mt-2 head-title">
                  <h2 className="big-title-light color-blue ">{jobDetails.title}</h2>
                  <div className="works-categories ">                      
                        <span className="color-blue  mob-job top-desc">
                           { translated('app_copy.company_location') } - {jobDetails?.job_nature} {jobDetails?.type}
                        </span>                      
                  </div>
                  <button className="primary-cta mr-1 apply" >
                        <span className="">
                          <a id="apply-now-action" href="#apply-now" onClick={ e => {clickedApplyNow()} }>{translated('cta.apply_now')}</a>
                        </span>
                      </button>
                  </div>


                
                  <div className="works-categories  ">                      
                        <span className="color-blue desk-job">
                          { translated('app_copy.company_location') } - {jobDetails?.job_nature} {jobDetails?.type}
                        </span>                      
                  </div>
                  <div className="right-side mb-4 mob-job">
                    <div className="works-categories mt-2">
                      {
                        jobDetails?.tag?.map((item: any, index: any) => {
                          return (
                            <button className="primary-cta mr-1" key={index}>
                              <span className="color-blue">{item}</span>
                            </button>
                          )
                        })
                      }
                    </div>
                    <div className='company-details mt-3 body-text'>{ translated('app_copy.company_about') }</div>
                  </div>

                  <Accordion defaultActiveKey={['0']}>
                    {
                      jobDetails?.details?.overview && 
                      <CustomAccordionItem content={jobDetails?.details?.overview} eventKey="0"     headerTitle="app_copy.overview" />
                    }
                    {
                      jobDetails?.details?.you_will && 
                      <CustomAccordionItem content={jobDetails?.details?.you_will} eventKey="1"     headerTitle="app_copy.you_will" />
                    }
                    {
                      jobDetails?.details?.leadership_expectations && 
                      <CustomAccordionItem content={jobDetails?.details?.leadership_expectations} eventKey="2"     headerTitle="app_copy.leadership_expectations" />
                    }
                    {
                      jobDetails?.details?.you_have && 
                      <CustomAccordionItem content={jobDetails?.details?.you_have} eventKey="3"     headerTitle="app_copy.you_have" />
                    }
                    {
                      jobDetails?.details?.benefits && 
                      <CustomAccordionItem content={jobDetails?.details?.benefits} eventKey="4"     headerTitle="app_copy.benefits" />
                    }
                    {
                      jobDetails?.details?.why_you_love_working_here && 
                      <CustomAccordionItem content={jobDetails?.details?.why_you_love_working_here} eventKey="5"
                      headerTitle="app_copy.why_you_love_working_here" />
                    }
                    {
                      jobDetails?.details?.belonging_at_claire && 
                      <CustomAccordionItem content={jobDetails?.details?.belonging_at_claire} eventKey="6"
                      headerTitle="app_copy.belonging_at_claire" />
                    }
                  </Accordion>
                  
                  <div className="form" id='apply-now'>
                    <h3 className="medium-title-light color-blue mt-2 mb-2" >
                      { translated('app_copy.apply_for_this_job') }
                    </h3>
                    <Accordion>
                    {
                      <Accordion.Item eventKey="7">
                        <Accordion.Header id="id-7" className='personal_data'>
                          <span className="accordion-header">{ translated('app_copy.personal_data') }</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className=''>
                            <div className="mb-3">
                              <div className="form-job-data d-flex">                    
                                <div className="mt-3 left-cnt">
                                  <div className="input-wrapper mt-15">
                                    <input type="text" name="first_name" onChange={e => handleChange(e)} value={formData.first_name} placeholder={ translated('app_copy.first_name') } />
                                  </div>
                                  { errorMsgs.first_name &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.first_name}</p>
                                  }

                                  <div className="input-wrapper mt-15">
                                    <input type="text" name="last_name" onChange={e => handleChange(e)} value={formData.last_name} placeholder={ translated('app_copy.last_name') } />
                                  </div>
                                  { errorMsgs.last_name &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.last_name}</p>
                                  }

                                  <div className="input-wrapper mt-15">
                                    <input type="enter_your_email" name="email" onChange={e => handleChange(e)}  value={formData.email} placeholder={ translated('app_copy.enter_your_email') } />
                                  </div>
                                  { errorMsgs['email'] !== undefined &&errorMsgs.email &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.email}</p>
                                  }

                                  <div className="input-wrapper mt-15">
                                    <input type="text" name="phone_number"
                                    onChange={e => handleChange(e)} value={formData.phone_number} placeholder={ translated('app_copy.phone_number') } />
                                  </div>
                                  { errorMsgs.phone_number &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.phone_number}</p>
                                  }
                                        <div className="right-cnt mt-3 mob-job">
                                {
                                  settingData?.resume?.is_visible &&
                                  <>
                                    <div className="d-flex-center resume-csv">
                                      <button className="primary-cta" onClick={resumeHandleClick} id="resume">
                                        <img src={uploadIcon} alt="Upload" className="upload-icon mr-1" />
                                        <span className="gradient-text bold">{ translated('cta.resume') }</span>
                                      </button>
                                      <input type="file" name="resume" id="files"
                                        onChange={(e) => handleFileChange(e)}
                                        ref={resumeFileInput}
                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.rtf" className="d-none" />
                                      <p className="body-text-small mt-1">{resumeFileName != "" ? resumeFileName : translated('app_copy.pdf_word_rich_text')}</p>
                                    </div>
                                    { errorMsgs.resume &&
                                      <p className="error_msg color-red ms-4">{errorMsgs.resume}</p>
                                    }
                                  </>
                                }
                                {
                                  settingData?.cover_letter?.is_visible && 
                                  <>
                                    <div className="d-flex-center">
                                      <button className="primary-cta" onClick={coverHandleClick} id="cover_letter">
                                        <img src={uploadIcon} alt="Upload" className="upload-icon mr-1" />
                                        <span className="gradient-text bold">{ translated('cta.cover_letter') }</span>
                                      </button>
                                      <input type="file" name="cover_letter" id="files2"
                                        onChange={(e) => handleFileChange(e)}
                                        ref={coverFileInput}
                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.rtf" className="d-none" />
                                      <p className="body-text-small mt-1">{coverFileName != "" ? coverFileName : translated('app_copy.pdf_word_rich_text')}</p>
                                    </div>
                                    { errorMsgs.cover_letter &&
                                      <p className="error_msg color-red ms-4">{errorMsgs.cover_letter}</p>
                                    }
                                  </>
                                }
                                </div>

                                  <div className="input-wrapper mt-15">
                                    <input type="text" name="linkedin_profile" onChange={e => handleChange(e)}  value={formData.linkedin_profile} placeholder={ translated('app_copy.linkedin_profile') } />
                                  </div>
                                  { errorMsgs.linkedin_profile &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.linkedin_profile}</p>
                                  }

                                  <div className="input-wrapper mt-15">
                                    <input type="text" name="website" onChange={e => handleChange(e)}  value={formData.website} placeholder={ translated('app_copy.website') } />
                                  </div>
                                  { errorMsgs.website &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.website}</p>
                                  }

                                  <div className="input-wrapper mt-15">
                                    <input type="text" name="how_hear" onChange={e => handleChange(e)}  value={formData.how_hear} placeholder={ translated('app_copy.how_hear') } />
                                  </div>
                                  { errorMsgs.how_hear &&
                                    <p className="error_msg color-red ms-4">{errorMsgs.how_hear}</p>
                                  }
                                </div>
                                <div className="right-cnt mt-3 desk-job">
                                {
                                  settingData?.resume?.is_visible &&
                                  <>
                                    <div className="d-flex-center resume-csv">
                                      <button className="primary-cta" onClick={resumeHandleClick} id="resume">
                                        <img src={uploadIcon} alt="Upload" className="upload-icon mr-1" />
                                        <span className="gradient-text bold">{ translated('cta.resume') }</span>
                                      </button>
                                      <input type="file" name="resume" id="files"
                                        onChange={(e) => handleFileChange(e)}
                                        ref={resumeFileInput}
                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.rtf" className="d-none" />
                                      <p className="body-text-small mt-1">{resumeFileName != "" ? resumeFileName : translated('app_copy.pdf_word_rich_text')}</p>
                                    </div>
                                    { errorMsgs.resume &&
                                      <p className="error_msg color-red ms-4">{errorMsgs.resume}</p>
                                    }
                                  </>
                                }
                                {
                                  settingData?.cover_letter?.is_visible && 
                                  <>
                                    <div className="d-flex-center">
                                      <button className="primary-cta" onClick={coverHandleClick} id="cover_letter">
                                        <img src={uploadIcon} alt="Upload" className="upload-icon mr-1" />
                                        <span className="gradient-text bold">{ translated('cta.cover_letter') }</span>
                                      </button>
                                      <input type="file" name="cover_letter" id="files2"
                                        onChange={(e) => handleFileChange(e)}
                                        ref={coverFileInput}
                                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.rtf" className="d-none" />
                                      <p className="body-text-small mt-1">{coverFileName != "" ? coverFileName : translated('app_copy.pdf_word_rich_text')}</p>
                                    </div>
                                    { errorMsgs.cover_letter &&
                                      <p className="error_msg color-red ms-4">{errorMsgs.cover_letter}</p>
                                    }
                                  </>
                                }
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    }
                    {
                      <Accordion.Item eventKey="8">
                        <Accordion.Header id="id-8">
                          <span className="accordion-header">
                            { translated('app_copy.voluntary') }
                          </span></Accordion.Header>
                        <Accordion.Body>
                          <div className='reset-design'>                            
                            { translated('app_copy.voluntary_notes') }                            
                            <div className="mb-3">                                
                              <div className="input-wrapper m-2 ">
                                <label htmlFor="gender">{translated('app_copy.gender')}</label><br/>
                                <select name="gender" onChange={handleChange} value={formData.gender} className='selection'>
                                  <option value="">{translated('app_copy.please_select')}</option>
                                  <option value="male">{translated('app_copy.male')}</option>
                                  <option value="female">{translated('app_copy.female')}</option>
                                  <option value="dont_wish_to_answer">{translated('app_copy.dont_wish_to_answer')}</option>
                                </select>
                              </div>                              
                              <div className='mt-2'>
                                <a className='mt-4' href={translated('app_copy.race_ethnicity_pdf')} target="__blank">{translated('app_copy.race_ethnicity_definitions')}</a> <br/>
                                <span className='mt-4'>
                                  {translated('app_copy.race_ethinicity_description')}
                                </span>
                              </div>                              
                              <div className="input-wrapper m-2">
                                <label htmlFor="veteran_status">{translated('app_copy.veteran_status')}</label> <br/>
                                <select name="veteran_status" onChange={handleChange} value={formData.veteran_status} className='selection'>
                                  <option value="">{translated('app_copy.please_select')}</option>
                                  <option value="not_protected_veteran">{translated('app_copy.not_protected_veteran')}</option>
                                  <option value="protected_veteran">{translated('app_copy.protected_veteran')}</option>
                                  <option value="dont_wish_to_answer">{translated('app_copy.dont_wish_to_answer')}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    }
                    {
                      <Accordion.Item eventKey="9">
                        <Accordion.Header id="id-9"><span className="accordion-header">{ translated('app_copy.voluntary_disability') }</span></Accordion.Header>
                        <Accordion.Body>
                          <div className='reset-design mt-4'>                                    
                            { translated('app_copy.voluntary_disability_notes') }                           
                            <div className="mb-3">                                
                              <div className="input-wrapper m-2">
                                <label htmlFor="gender">{translated('app_copy.disability_status')}</label><br/>
                                <select name="disability_status" onChange={handleChange} value={formData.disability_status} className='selection'>
                                  <option value="">{translated('app_copy.please_select')}</option>
                                  <option value="yes_disability">{translated('app_copy.yes_disability')}</option>
                                  <option value="dont_disability">{translated('app_copy.dont_disability')}</option>
                                  <option value="dont_wish_to_answer">{translated('app_copy.dont_wish_to_answer')}</option>
                                </select>
                              </div>
                            </div>  
                          </div>
                          <span className='mb-4 mt-4'>
                            { translated('app_copy.public_burden_statement') }
                          </span>
                        </Accordion.Body>
                      </Accordion.Item>
                    }
                    </Accordion>
                    {
                      isMiniLoader ? (<ClaireSpinner scale={0.20} className="inline-page-loader" />)
                      :
                      <>                        
                        <div className="d-flex-center submit-job">
                          <button className={'primary-cta mt-3 ' + disable} id="submitBtn" name="Submit Application"
                            disabled={disable} onClick={() => submitData()}>
                            <span className="gradient-text bold">{ translated('cta.submit_application') }</span>
                          </button>
                        </div>
                      </>
                    }
                  </div>
                  <div className='mb-2 mt-2'>                      
                    <p className="error_msg color-red text-align-center">{errorMsg}</p>
                    <p className="result_msg text-align-center color-blue"><b>{resultMsg} </b></p>
                  </div>
                </div>
                <div className="right-side mb-4 desk-job">
                  <div className="works-categories mt-2">
                    {
                      jobDetails?.tag?.map((item: any, index: any) => {
                        return (
                          <button className="primary-cta mr-1" key={index}>
                            <span className="color-blue">{item}</span>
                          </button>
                        )
                      })
                    }
                  </div>
                  <div className='company-details mt-3 body-text'>{ translated('app_copy.company_about') }</div>
                </div>
            </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default JobDetails;