import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { validateEmail } from '@/helper/common';
import { contactUs } from '@/services/api/fireFunction';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';

const ContactUs: React.FC = () => {
  const { translated } = useContext(AppContext)
  const [isLoader, setIsLoader] = useState(true);
  const [isMiniLoader, setIsMiniLoader] = useState(false);
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    consent: false,
  });
  const [errors, setErrors] = useState<any>({});

  const [errorMsg, setErrorMsg] = useState('');
  const [resultMsg, setResultMsg] = useState('');
  useEffect(() => {
    document.body.className = "contact-pg";
    return () => {
      document.body.className = "";
    };
  }, []);
  
  const handleChange = (e:any) => {
    let value = e.target.value;
    if(e.target.name === 'consent') {
      value = e.target.checked;
    }
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  useEffect(() => {
    ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  }, []);

  const handleFormSubmit = (e:any) => {
    e.preventDefault();

    setIsMiniLoader(true);    
    ReactRecaptcha3.getToken().then(
      (token: any) => {
        //console.log(token);
        processFormData(token);
      },
      (error: any) => {
        console.log(error);
        setIsMiniLoader(false);
      }
    );
  };

  const processFormData = async (token:any) => {
    const newErrors = validate(values);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) { 
      const contact_us_data:any = {};

      contact_us_data.first_name = values.first_name;
      contact_us_data.last_name = values.last_name;
      contact_us_data.email = values.email;
      contact_us_data.message = values.message;
      contact_us_data.token = token;

      let contactUsAPi = await contactUs(contact_us_data) as any;
      if(contactUsAPi?.data?.success?.status) {
        setResultMsg(translated('success.contact_us_submitted'));
       // $('.result_msg').fadeIn();
        $('.contact_form').hide();
        $('.thankyou').fadeIn();

      }
      else if(!contactUsAPi?.data?.error?.status) {          
        setErrorMsg(contactUsAPi?.data?.error?.message);
        $('.result_msg').fadeOut('slow');
      }
      else {        
        setErrorMsg(translated('error.something_wrong'));
      }
    }
    setIsMiniLoader(false);
  }

  const validate = (values:any) => {
    const errors:any = { };

    if (!values.first_name.trim()) {
      errors.first_name = translated('app_copy.contact_us_first_name_validation');
    }

    if (!values.last_name.trim()) {
      errors.last_name = translated('app_copy.contact_us_last_name_validation');
    }

    if (!values.email.trim() || !validateEmail(values.email)) {
      errors.email = translated('app_copy.contact_us_email_validation');
    }

    if (!values.message.trim() || values.message.length < 1) {
      errors.message = translated('app_copy.contact_us_message_validation');
    }
    
    if (!values.consent) {
      errors.consent = translated('app_copy.contact_us_checkbox_consent_mandatory');
    }

    return errors;
  };

  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={!isLoader} minTime={2000} scale={0.25} className="normal-page-loader">
          <Helmet>
            <title>Claire, Contact Us.</title>
            <meta name="description" content="Claire, Contact Us." />
          </Helmet>
          <div className="shadow-fixed"></div>      
              <div className="contact-us-wrapper desk-only ">
                <div className='contact-us row d-flex'>
                  <div className="get_in_touch ">                    
                    <h2 className="big-title-light color-blue">{translated('app_copy.contact_us_get_in_touch') }</h2>
                    <p>{translated('app_copy.contact_us_any_question') }</p>
                  </div>
                  <form className="contact_form" onSubmit={(e) => handleFormSubmit(e)}>
                    <div className={"input-wrapper " + (errors?.first_name ? 'has-error' : '') }>
                      <input type="text" maxLength={25} name="first_name" placeholder={ translated('app_copy.contact_us_first_name') } value={values.first_name} onChange={handleChange} />
                    </div>
                    
                    { errors?.first_name &&
                      <p className="error_msg color-red ms-4">{errors?.first_name}</p>
                    }
                    
                    <div className={"input-wrapper " + (errors?.last_name ? 'has-error' : '') }>
                      <input type="text" maxLength={25} name="last_name" placeholder={ translated('app_copy.contact_us_last_name') } value={values.last_name} onChange={handleChange} />
                    </div>
                    
                    { errors?.last_name &&
                      <p className="error_msg color-red ms-4">{errors.last_name}</p>
                    }
                    
                    <div className={"input-wrapper " + (errors?.email ? 'has-error' : '') }>
                      <input type="email" name="email" placeholder={ translated('app_copy.contact_us_email') }
                      value={values.email} onChange={handleChange} />
                    </div>
                    
                    { errors?.email &&
                      <p className="error_msg color-red ms-4">{errors.email}</p>
                    }
                    
                    <div className={"input-wrapper " + (errors?.message ? 'has-error' : '') }>
                    <textarea name="message" placeholder={ translated('app_copy.contact_us_message') } 
                        value={values.message} onChange={handleChange}  > </textarea>
                    </div>
                    
                    { errors?.message &&
                      <p className="error_msg color-red ms-4">{errors.message}</p>
                    }
                    <div className={"consent-checkbox "+(errors?.consent ? 'has-error': '') }>
                      <input type="checkbox" name="consent" id="consent-checkbox" checked={values.consent} onChange={handleChange} />
                      <label htmlFor="consent-checkbox">
                        <span className="body-text-small color-gray">
                          {translated('app_copy.contact_us_checkbox_consent')}
                        </span>
                      </label>
                    </div>
                    { errors?.consent &&
                      <p className="error_msg color-red ms-4">
                        {translated('app_copy.contact_us_checkbox_consent_mandatory')}
                      </p>
                    }
                    
                    {
                      isMiniLoader ? (<ClaireSpinner scale={0.20} className="inline-page-loader" />)
                      :
                      <>        
                        <div className=" submit-form">
                          <button type='submit' className='primary-cta' id="submitBtn" name="Submit">
                            <span className="gradient-text bold">{ translated('cta.contact_us_submit') }</span>
                          </button>
                        </div>
                        </>
                    }
                  </form>
                  <div className="thankyou">
                    <h2>{ translated('success.contact_us_thanks') }</h2>
                    <p>{ translated('success.contact_us_submitted') }</p>
                  </div>
                </div>
                <div className='mb-2 mt-2'>
                  <p className="error_msg color-red text-align-center">{errorMsg}</p>                      
                {/*  <p className="result_msg text-align-center color-blue"><b>{resultMsg} </b></p>*/}
                </div>
              </div>
          
          
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default ContactUs;

