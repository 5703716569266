import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { useNavigate } from "react-router-dom";
import { getJobs } from "@/services/firebase/fireStore";
import { Helmet } from "react-helmet";
import { AppContext } from "@/context/createContext";
import Spinner from "@/components/common/Spinner";
import { Accordion } from "react-bootstrap";
import { pasaca, liveperson } from "@/config/svg";
import 'bootstrap/dist/js/bootstrap.min.js';
import routes from "@/config/routes";


const TermsOfUse: React.FC = () => {
  const { translated,ClaireLoader } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    $(document).ready(function () {
      // eslint-disable-next-line no-restricted-globals
      scrollTo(0, 0);
    });
  });
  useEffect(() => {
    setTimeout(() => {
      $(document).ready(function () {
        // eslint-disable-next-line no-restricted-globals
        $('#custom_route').click(function () {
          navigate(routes.PrivacyPolicy)
        });
      });
    }, 500);
    
    document.body.className = "terms-of-use";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Pages>
        <Helmet>
          <title>Claire, Terms Of Use.</title>
          <meta name="description" content="Claire, Terms Of Use." />
        </Helmet>
        <div className="shadow-fixed"></div>
        <div className="d-flex-center h100">
          <div className="d-flex-center h100 terms">
            <h2 className="big-title-light color-blue text-animation  fadeInBottom ">
              {translated("app_copy.terms_of_use")}
            </h2>

            <div className="terms-cnt">
              <div className="accordion body-text color-black mt-2 text-align-center text-animation fadeInBottom sequence2" id="accordionPanel">
                { translated('app_copy.terms_of_use_description') }
              </div>
            </div>
          </div>
        </div>
      </Pages>
    </>
  );
};

export default TermsOfUse;
