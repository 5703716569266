import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { auth } from '../firebase/index'
// const token = await auth.currentUser?.getIdToken()

export const submitApplication = async (payload: any, access_token: string = '') => {
    try {
        const token = await auth.currentUser?.getIdToken();

        const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/jobApplication/submitApplication`;

        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            })
        return response
    } catch (error: any) {
        return errorProcess(error);
    }
}

export const subscribe = async(payload:any) => {
    try {
        const token = await auth.currentUser?.getIdToken();
        const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/mail/subscription`;
        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            });
        return response;
    }
    catch (error:any) {
        return errorProcess(error);
    }
}

export const unsubscribe = async(payload:any) => {
    try {
        const token = await auth.currentUser?.getIdToken();
        const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/mail/unsubscription`;
        
        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            });
        return response;
    }
    catch (error:any) {
        return errorProcess(error);
    }
}

export const contactUs = async(payload:any) => {
    try {
        const token = await auth.currentUser?.getIdToken();
        const url = `${process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL}/api/contactUs`;
        
        const response = await axios
            .post(url, payload, {
                headers: {
                    'authorization': 'Bearer ' + token
                }
            });
        return response;
    }
    catch (error:any) {
        return errorProcess(error);
    }
}

export const errorProcess = (error:any) => { 
    if(error?.response?.status === 401) {
        let response = {
            data: {
                error: {
                    status: error.response.data.error.status,
                    message: error.response.data.error.message
                }
            }
        }
        return response;
    }
    else {
        return {error: error?.response}
    }
};