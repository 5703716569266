import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";

import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import {
  phonesTelehealth,
  videoCall,
  phoneBiomarker,
  femaleDoctor,
  back
} from "@/config/svg";
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';

const DigitalHealthProvider: React.FC = () => {
  const { translated } = useContext(AppContext)
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    document.body.className = "who-pg ";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Pages>
        <ClaireSpinnerTimer showSpinner={!isLoader} minTime={4000} scale={0.25} className="normal-page-loader">
          <Helmet>
            <title>Claire, Digital Health Providers.</title>
            <meta name="description" content="Claire, Digital Health Providers." />
          </Helmet>
          <div className="shadow-fixed"></div>
          <div className="back-description ">
            <Link to="/who-we-serve" state={{ prevPage: "digital-health-provider" }} className="link-text color-black">
              <img src={back} alt="Back" className="mr-1" />
              <span className="body-text back-cta">{translated('cta.back')}</span>
            </Link>
            <div className="desc">
              <span></span><p>{translated('app_copy.digital_health_title')}</p>
            </div>
          </div>
          <div className=" block-1">
            <div className="left-txt">
              <h1>{translated('app_copy.digital_health_virtual_experience')}</h1>
            </div>
            <div className="right-img"><img src={phonesTelehealth} alt='phones'></img></div>
          </div>
          <div className="block-2 digital ">
            <div><img src={videoCall} alt='videocall'></img></div>
            <div className="position-relative box-purple">
              <div className="floating-box-bottom">
                <p>{translated('app_copy.digital_health_telehealth_service')}</p>
              </div>
            </div>
          </div>

          <div className="block-3 digital">
            <div className="left-text">
              <div className="text-1">
                {translated('app_copy.digital_health_claire_helps')}
              </div>
              <div className="mob-only">
                <img src={phoneBiomarker} alt='phone-screen'></img>
              </div>
              {translated('app_copy.digital_health_tests_convenience')}
            </div>
            <div className="right-img desk-only">
              <img src={phoneBiomarker} alt='phone-screen'></img>
            </div>

          </div>
          <div className="block-4 position-relative">
            <div><img src={femaleDoctor} alt='doctor'></img></div>
            <div className="floating-box-right">
              {translated('app_copy.digital_health_claire_can')}
            </div>

          </div>
          <div className="block-5">
            <div className="box">
              {translated('app_copy.digital_health_enhance_healthcare')}
            </div>
            <div className="box">
              {translated('app_copy.digital_health_improve_experience')}
            </div>
            <div className="box">
              {translated('app_copy.digital_health_demonstrate_quality')}
            </div>

          </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default DigitalHealthProvider;
