import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import Spinner from '@/components/common/Spinner';
import { Mousewheel, Pagination } from "swiper";
import { validateEmail } from '@/helper/common';
//import { isSubscribed, subscribe } from '@/services/firebase/fireStore';
//import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { subscribe as mailSubscribe } from '@/services/api/fireFunction';
import {
  mute, unmute
} from "@/config/svg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { deleteUser, getAuth, getIdToken, onAuthStateChanged } from 'firebase/auth';
import { authentication } from '@/services/firebase/authentication';
import ClaireSpinner from '@/components/claireSpinner/ClaireSpinner';

const Home: React.FC = () => {
  const { translated, ClaireLoader, authUser, videoUrl, videoUrlMobile } = useContext(AppContext);
  const fetchCalled = useRef(false);
  const isPaused = useRef(false);
  const [isMiniLoader, setIsMiniLoader] = useState(false);
  const scollToRef = useRef();
  const [scrollTop, setScrollTop] = useState(0);
  const privacyAlertElement = window.document.getElementById('lanyard_root') as any;

  useEffect(() => {
    const handleScroll = (event: any) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf('?hp') > -1) {
      $('html,body').animate({ scrollTop: $(document).height() }, "fast");
      const videoM = document.getElementById('video-js-m') as HTMLVideoElement;
      videoM.pause();
    } else {
      $('html,body').animate({ scrollTop: 0 }, 'fast');

    }
  }, []);

  useEffect(() => {
    const video = document.getElementById('video-js') as HTMLVideoElement;
    const videoM = document.getElementById('video-js-m') as HTMLVideoElement;


    if (videoUrlMobile && videoUrl && video) {
      const source = document.createElement('source');
      const sourceM = document.createElement('source');

      if (window.innerWidth < 900) {
        sourceM.setAttribute('src', videoUrlMobile);
        sourceM.setAttribute('type', 'video/mp4');
        videoM.appendChild(sourceM);
        let x: any = $(window).height();

        if (privacyAlertElement?.innerHTML.length !== 0) {
          privacyAlertElement.style.display = 'none';
        }

        if (!fetchCalled.current) {
          //console.log('72->play',scrollTop);
          videoM.play();
        }
        else if (scrollTop > 35 || window.location.href.indexOf('?hp') > -1) {
          //console.log('76->pause',scrollTop);
          videoM.pause();
          isPaused.current = true;

          if (privacyAlertElement?.innerHTML.length !== 0) {
            privacyAlertElement.style.display = 'block';
          }
          
        } 
       
      } else {
        source.setAttribute('src', videoUrl);
        source.setAttribute('type', 'video/mp4');
        video.appendChild(source);
        video.play();

        if (privacyAlertElement?.innerHTML.length !== 0) {
          privacyAlertElement.style.display = 'none';
        }

        if (window.location.href.indexOf('?hp') > -1) {
          video.pause();
        }
      }
      fetchCalled.current = true;
    }
    // $('.mobile-hp2').css('opacity','1')
    //  if (window.location.href.indexOf('?hp') > -1) {
    //   videoM.pause();
    //  }else{
    // if (scrollTop > 20) {
    //   console.log('121',scrollTop);
    //   videoM.pause();

    //   if(privacyAlertElement?.innerHTML.length !== 0) {
    //     privacyAlertElement.style.display = 'block';
    //   }

    // } else {
    //   console.log('129',scrollTop);
    //   videoM.play();
    // }
    //}
  });

  const [email, setEmail] = useState("");
  const [consent, setConsent] = useState(false);
  const [disable, setDisable] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [resultMsg, setResultMsg] = useState('');

  const updateEmail = (inputValue: string) => {
    setEmail(inputValue);
  };

  const updateConsent = (inputValue: any) => {
    setConsent(inputValue);
  }

  const handleSubmitData = async () => {
    setErrorMsg('');
    if (!email || !validateEmail(email)) {
      setErrorMsg(translated('error.insert_a_valid_email'));
    }
    else if (!consent) {
      setErrorMsg(translated('error.the_conset_is_mandatory'));
    }
    else {
      setIsMiniLoader(true);

      let dt = new Date();
      let isodt = toIsoString(dt);

      const auth = getAuth();
      const currentUser: any = auth?.currentUser;
      var user_id = currentUser?.uid;

      const newUser = {
        date_time: isodt,
        email: email,
        uid: user_id
      }
      const subscription = await mailSubscribe(newUser) as any;
      
      if (subscription?.data?.success?.status) {
        setConsent(false);
        setEmail('');
        setErrorMsg('');
        //setResultMsg(translated('success.thank_you_signup'));
        setResultMsg(subscription?.data?.success?.message);
        $('.result_msg').fadeIn();
        $('.email-subscribe-input, .consent-checkbox, .medium-title').hide();
        setIsMiniLoader(false);

        setTimeout(function () {
          $('.result_msg').fadeOut();
          $('.email-subscribe-input, .consent-checkbox, .medium-title').show();
          //$("#std-pre-launch").show();
        }, 5000);

        deleteUser(currentUser).then(async () => {
          //console.log('deleteUser successfully.');
          await authentication.signInAnonymously();
        }).catch((error) => {
          //console.log('deleteUser failed.');
        });
      }
      else if (!subscription?.data?.error?.status) {
        //setErrorMsg(translated('error.mail_already_used'));
        setErrorMsg(subscription?.data?.error?.message);
        $('.result_msg').fadeOut();
        setIsMiniLoader(false);

        setTimeout(function () {
          $('.result_msg').fadeOut();
        }, 5000);
      }
    }
  }

  function toIsoString(date: any) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function (num: number) {
        return (num < 10 ? '0' : '') + num;
      };

    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(Math.floor(Math.abs(tzo) / 60)) +
      ':' + pad(Math.abs(tzo) % 60);
  }

  const [mutedBtnText, setMutedBtnText] = useState("Unmute");

  const unmuteVideo = () => {
    const video = document.getElementById('video-js') as HTMLVideoElement;
    const videoM = document.getElementById('video-js-m') as HTMLVideoElement;
    video.muted = !video.muted;
    videoM.muted = !videoM.muted;
    if (video.muted) {
      setMutedBtnText("Unmute");
    }
    else {
      setMutedBtnText("Mute")
    }
    if (videoM.muted) {
      setMutedBtnText("Unmute");
    }
    else {
      setMutedBtnText("Mute")
    }
  }

  useEffect(() => {
    document.body.className = "hp2-pg";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Pages>
        <Helmet>
          <title>Claire.</title>
          <meta name="description" content="Claire, Terms Of Use." />
          <meta name="viewport" content="width=device-width, initial-scale=1,user-scalable=no"></meta>
        </Helmet>
        <div className="shadow-fixed"></div>
        <Swiper
          direction={"vertical"}
          slidesPerView={1}
          spaceBetween={30}
          mousewheel={true}
          pagination={{
            clickable: true,
          }}
          speed={2500}
          modules={[Mousewheel, Pagination]}
          className="mySwiper"

          onSlideChangeTransitionStart={(swiper) => {

            const video = document.getElementById('video-js') as HTMLVideoElement;
            if (swiper.realIndex == 0) {
              $('.footer-wrapper').addClass('hide-footer');
              // video.play();
            } else {
              $('.footer-wrapper').removeClass('hide-footer');
              video.pause();

              if (privacyAlertElement?.innerHTML.length !== 0) {
                privacyAlertElement.style.display = 'block';
              }
            }
          }}
          onSlidePrevTransitionEnd={(swiper) => {
            const video = document.getElementById('video-js') as HTMLVideoElement;
            if (swiper.realIndex == 0) {
              $('.footer-wrapper').addClass('hide-footer');
              video.pause();
            } else {
              $('.footer-wrapper').removeClass('hide-footer');
              video.pause();
            }
          }}
          onBeforeInit={
            (swiper) => {
              if (window.innerWidth < 900) {
              } else {
                if (swiper.realIndex == 0) {
                  $('.footer-wrapper').addClass('hide-footer');
                } else {
                  $('.footer-wrapper').removeClass('hide-footer');
                }
              }
            }
          }
          onSwiper={(swiper) => {
            if (window.location.href.indexOf('?hp') > -1) {
              swiper.slideTo(1);

              const video = document.getElementById('video-js') as HTMLVideoElement;
              const videoM = document.getElementById('video-js-m') as HTMLVideoElement;
              video.pause();
              videoM.pause();

            }
          }}
        >
          <SwiperSlide> <div className="page-section section-1">
            <button className="unmute-video" onClick={(e) => unmuteVideo()}>
              <img src={mutedBtnText == 'Mute' ? unmute : mute}></img>
            </button>
            <div className="video-background-wrapper">
              <video id="video-js" playsInline muted autoPlay >
              </video>
            </div>
            <div className="c-scrolldown">

              <span className="c-text color-blue">{translated('app_copy.we_are_about')}</span>
              <div className="c-line"></div>
            </div>
          </div></SwiperSlide>
          <SwiperSlide>
            <div >
              <div className="sub-section2"  >
                <div className="d-flex-center h100 hp-text">
                  <div className="d-flex-center infos">
                    <h3 className="medium-title coming-spring color-blue">
                      {translated('app_copy.i_want_to_know_more')}
                    </h3>
                    <div className="title">
                      {translated('app_copy.intro_title')}
                    </div>
                    <div className="text ">
                      {translated('app_copy.intro_text')}
                    </div>
                  </div>

                </div>
              </div>
              <div className="c-scrolldown">
                <span className="c-text color-blue">{translated('app_copy.keep_me_in_the_loop')}</span>
                <div className="c-line"></div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <div className="sub-section2"  >
                <div className="d-flex-center h100 hp-text">
                  <div className="d-flex-center ">
                    <div className="title slide3">
                      {translated('app_copy.want_to_know_launch')}
                    </div>
                  </div>
                  <div className="d-flex-center  coming-soon-wrapper">
                    <div className="email-subscribe-input  mt-3">
                      <input type="email" name="email-subscribe" id="email-subscribe" placeholder={translated('app_copy.enter_your_email')} autoFocus={false} style={errorMsg ? { border: '1px solid red' } : {}}
                        onChange={(e) => updateEmail(e.target.value)} value={email} />
                      <button type="submit" className="submit color-white" disabled={!disable}
                              onClick={handleSubmitData} name="Submit"><span>{translated('cta.submit')}</span></button>
                    </div>
                    {errorMsg &&
                      <p className="error_msg color-red ">{errorMsg}</p>
                    }
                    {
                      isMiniLoader ? (<ClaireSpinner scale={0.20} className="inline-page-loader" />) : <></>
                    }
                    <div className="consent-checkbox" >
                      <input type="checkbox" name="consent" id="consent-checkbox" checked={consent} onChange={e => updateConsent(e.target.checked)} />
                      <label htmlFor="consent-checkbox">
                        <span className="body-text-small color-gray">
                          {translated('app_copy.checkbox_consent')}
                        </span>
                      </label>
                    </div>
                    {resultMsg &&
                      <p className="result_msg color-blue m-1 bold">{resultMsg}</p>
                    }
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="mobile-hp2">
          <div className="page-section section-1 pg-mb">
            <button className="unmute-video" onClick={(e) => unmuteVideo()}>
              <img src={mutedBtnText == 'Mute' ? unmute : mute}></img>
            </button>
            <div className="video-background-wrapper">
              <video id="video-js-m" playsInline muted autoPlay>
              </video>
            </div>
            <div className="c-scrolldown">
              <span className="c-text color-blue">{translated('app_copy.we_are_about')}</span>
              <div className="c-line"></div>
            </div>
          </div>
          <div className="pg-mb textual">
            <div className="sub-section2"  >
              <div className="d-flex-center h100 hp-text">
                <div className="d-flex-center infos">
                  <h3 className="medium-title coming-spring color-blue">
                    {translated('app_copy.i_want_to_know_more')}
                  </h3>
                  <div className="title">
                    {translated('app_copy.intro_title')}
                  </div>
                  <div className="text ">
                    {translated('app_copy.intro_text')}
                  </div>
                </div>

              </div>
            </div>
            <div className="c-scrolldown">
              <span className="c-text color-blue">{translated('app_copy.keep_me_in_the_loop')}</span>
              <div className="c-line"></div>
            </div>
          </div>
          <div className="pg-mb textual">
            <div className="sub-section2"  >
              <div className="d-flex-center h100 hp-text">
                <div className="d-flex-center ">
                  <div className="title slide3">
                    {translated('app_copy.want_to_know_launch')}
                  </div>
                </div>
                <div className="d-flex-center  coming-soon-wrapper">
                  <div className="email-subscribe-input  mt-3">
                    <input type="email" name="email-subscribe" id="email-subscribe" placeholder={translated('app_copy.enter_your_email')} autoFocus={false} style={errorMsg ? { border: '1px solid red' } : {}}
                      onChange={(e) => updateEmail(e.target.value)} value={email} />
                    <button type="submit" className="submit color-white" disabled={!disable}
                            onClick={handleSubmitData} name="Submit"><span>{translated('cta.submit')}</span></button>
                  </div>
                  {errorMsg &&
                    <p className="error_msg color-red ">{errorMsg}</p>
                  }
                  {
                    isMiniLoader ? (<ClaireSpinner scale={0.20} className="inline-page-loader" />) : <></>
                  }
                  <div className="consent-checkbox" >
                    <input type="checkbox" name="consent" id="consent-checkbox" checked={consent} onChange={e => updateConsent(e.target.checked)} />
                    <label htmlFor="consent-checkbox">
                      <span className="body-text-small color-gray">
                        {translated('app_copy.checkbox_consent')}
                      </span>
                    </label>
                  </div>
                  {resultMsg &&
                    <p className="result_msg color-blue m-1 bold">{resultMsg}</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Pages>
    </>
  );
};

export default Home;
