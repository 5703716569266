import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef, useContext } from "react";

import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";
import { back } from "@/config/svg";
import { AppContext } from '@/context/createContext';

const Response404: React.FC = () => {
  const { translated } = useContext(AppContext);
    let location = useLocation();
  
      useEffect(() => {
        document.body.className = "error-404";
        return () => {
          document.body.className = "";
        };
      }, []);

    return (
        <>
            <Pages>
                <div className="shadow-fixed"></div>
                <div className="d-flex-center h100 response-404">
                    <div className="d-flex-center h100 ">
                        <h1 className="big-title-light color-blue ">{translated('app_copy.404_title')}</h1>
                        <p className="bold body-text">{translated('app_copy.404_sub_title')}</p>
                        <p className="body-text color-black mt-1 text-align-center ">
                            {translated('app_copy.404_intro')}                            
                        </p>
                        <Link to="/" className="link-text color-black">
                      <img src={back} alt="Back" className="mr-1" />
                      <span className="body-text back-cta">{translated('cta.go_back')}</span>
                    </Link>
                    </div>
                    </div>
            </Pages>
        </>
    );
}

export default Response404