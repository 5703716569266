import { createContext } from 'react'
import { FirebaseAuthState,  AppState } from '@/interface/index'
export type AppContextProps = {
    state: AppState;
    authUser: FirebaseAuthState;
    configState: any;
    settingsState: any;
    stepModules: any;
    translated: (text:string,search?:string[]|string|null  ,replace?:string[]|string|null)=>string;
    searchAndReplace:(text:string,search:string[]|string|null  ,replace:string[]|string|null)=>string;
    translateDynamic:(key:string,search:string[]|string|null  ,replace:string[]|string|null)=>string;
    mainLoader: boolean;
    remainingLoaderTime: boolean;    
    showToastMessage: (message: string, messageType?: boolean, options?:any) => void;
    setRootLoader: () => void;
    unSetRootLoader: () => void;
    updateTranslationLanguage: (lang:string) => void;
    setMinRootLoader: ()=> void;
    setMaxRootLoader: ()=> void;
    addCategory: (list: any) => void;
    newAnonymousUser: () => void;
    videoUrl:any,
    videoUrlMobile:any,
    ClaireLoader:(props:any) => any;
}
const AppContext = createContext<AppContextProps>({} as AppContextProps)

export { AppContext };

