import axios from 'axios'

export const locationAPI = async (location_api_url:any) => {
    try {
        const url = location_api_url;
        const response = await axios
            .get(url);
        return response
    } catch (error: any) {
        return {error: error?.response};
    }
}

