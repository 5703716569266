import React, { useEffect, useState, useRef, useContext } from "react";
import Pages from "@/layouts/Pages";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { AppContext } from '@/context/createContext';
import {
  phones,
  doctor,
  phoneBiomarker,
  back
} from "@/config/svg";
import ClaireSpinnerTimer from '@/components/claireSpinner/ClaireSpinnerTimer';

const ProviderAndClinic: React.FC = () => {
  const { translated } = useContext(AppContext)
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    document.body.className = "who-pg";
    return () => {
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Pages>        
        <ClaireSpinnerTimer showSpinner={!isLoader} minTime={4000} scale={0.25} className="normal-page-loader">
          <Helmet>
            <title>Claire, Provider and Clinic.</title>
            <meta name="description" content="Claire, Provider and Clinic." />
          </Helmet>
          <div className="shadow-fixed"></div>
          <div className="back-description ">
            <Link to="/who-we-serve" state={{ prevPage: "provider-and-clinic" }} className="link-text color-black">
              <img src={back} alt="Back" className="mr-1" />
              <span className="body-text back-cta">{translated('cta.back')}</span>
            </Link>
            <div className="desc">
            <span></span><p>{translated('app_copy.provider_clinic_title')}</p>
            </div>
          </div>
          <div className=" block-1">
            <div className="left-txt">
              {translated('app_copy.provider_clinic_top_left_text')}
            </div>
            <div className="right-img"><img src={phones} alt='phones'></img></div>
          </div>
          <div className="block-2 ">
            <div className="position-relative box-purple">
              <div className="floating-box">
                <p>
                  {translated('app_copy.provider_clinic_physician_desc')}
                </p>
              </div>
            </div>
            <div><img src={doctor}></img></div>
          </div>

          <div className="block-3 provider">
            <div className="left-img desk-only">
              <img src={phoneBiomarker} alt='phone-screen'></img>
            </div>
            <div className="right-text">
              <div className="text-1">
                  {translated('app_copy.provider_clinic_claire_helps')}
              </div>
              <div className="left-img mob-only">
                <img src={phoneBiomarker} alt='phone-screen'></img>
              </div>              
              {translated('app_copy.provider_clinic_testing_convenience')}
            </div>
          </div>
        </ClaireSpinnerTimer>
      </Pages>
    </>
  );
};

export default ProviderAndClinic;
