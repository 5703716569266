/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AppContextProvider } from "@/context/provider/AppContextProvider";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./AppRouter";
import React from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

function App() {
  
/*   useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
    window.addEventListener('resize', appHeight);
    appHeight();
  }); */

const analytics = getAnalytics();
logEvent(analytics, 'select_content', {
  content_type: 'string',
  content_id: window.location.pathname,
  items: [{ name: window.location.href, path:window.location.pathname }]
});

  return (
    <React.Suspense fallback="loading">
      <BrowserRouter>
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </BrowserRouter>
    </React.Suspense>
  );
}

export default App;
